import {
  Button,
  Form,
  Input,
  Select,
  Spin,
  Typography,
  message,
  Alert,
} from "antd";
import { mutationCreateUser, queryEnabledUsers } from "./utils/dataHooks";
import { useEffect, useState } from "react";
const { Title } = Typography;

export function CreateUser() {
  const [loaded, setLoaded] = useState(false);
  const [canCreate, setCanCreate] = useState(false);

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    const fetchUsers = async () => {
      const users = await queryEnabledUsers();
      if (!process.env.REACT_APP_MAX_USERS) {
        //if there is no env variables, one can create unlimited users
        setCanCreate(true);
      } else if (Number(process.env.REACT_APP_MAX_USERS) > users.length) {
        setCanCreate(true);
      } else {
        setCanCreate(false);
      }
      setLoaded(true);
    };
    if (!loaded) fetchUsers();
  }, [loaded]);
  const onClickCreateUser = () => {
    try {
      mutationCreateUser(form.getFieldsValue()).then((user) => {
        messageApi.info("Successfully created user " + user.name);
        form.resetFields();
        setLoaded(false)
      });
    } catch (err) {
      messageApi.error("Something went wrong");
    }
  };
  if (!loaded) return <Spin size="large" />;
  if (!canCreate)
    return (
      <Alert
        style={{ marginTop: 75 }}
        message={`You have reached the limit of ${process.env.REACT_APP_MAX_USERS} enabled users.`}
        description={
          <p>
            To add a new user, please contact support to upgrade your plan or
            deactivate existing users.
          </p>
        }
        type="warning"
        showIcon
      />
    );
  else
    return (
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
        form={form}
      >
        {contextHolder}
        <Title level={2} align={"center"}>
          Create a New User
        </Title>
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Group"
          name="group"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Select
            style={{
              width: 160,
            }}
            options={[
              {
                value: "Opener",
                label: "Opener",
              },
              {
                value: "Closer",
                label: "Closer",
              },
              {
                value: "Admin",
                label: "Admin",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          }}
        >
          <Button type="primary" onClick={onClickCreateUser}>
            Create User
          </Button>
        </Form.Item>
      </Form>
    );
}
