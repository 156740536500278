import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import SignIn from "./SignIn";
import { ConfigProvider } from "antd";
import theme from "./theme.json";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider theme={theme}>
      <SignIn />
    </ConfigProvider>
  </React.StrictMode>
);
