import { Amplify, Auth, API } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';


let nextToken;

export async function listEditors( group){


  let apiName = 'AdminQueries';
  let path = '/listUsersInGroup';
  let myInit = {
      queryStringParameters: {
        "groupname": group,
        // "limit": limit,
        "token": nextToken
      },
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
  }
  const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
  nextToken = NextToken;
  return rest;
}

export async function signUpUser({ username, password, email }, updateFormType) {
  try {
    await Auth.signUp({
      username: 'username',
      password: 'passwordTest',
      attributes: {
        email: 'email@ds.com'
      }})
    console.log('sign up success!')
    updateFormType('confirmSignUp')
  } catch (err) {
    console.log('error signing up..', err)
  }
}





export async function changeGroup (user,fromgroup,togroup) {

console.log(user,fromgroup,togroup);
  //We make a reusable function
  async function addremoveToGroup(user,group,query) {
   let apiName = 'AdminQueries';
   let path = '/'+query;
   let myInit = {
       body: {
         "username" : user,
         "groupname": group
       },
       headers: {
         'Content-Type' : 'application/json',
         Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
       }
   }
   return await API.post(apiName, path, myInit);
 }

 //We first remove the user from a group and then we add him
 await addremoveToGroup(user,fromgroup,'removeUserFromGroup');
  return await addremoveToGroup(user,togroup,'addUserToGroup');


}


export async function DeleteUser(user){


  let apiName = 'AdminQueries';
  let path = '/disableUser';
  let myInit = {
      body: {
        "username" : user
      },
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
  }
  return await API.post(apiName, path, myInit);

}


export async function EnableUser(user){


  let apiName = 'AdminQueries';
  let path = '/enableUser';
  let myInit = {
      body: {
        "username" : user
      },
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
  }
  return await API.post(apiName, path, myInit);

}
