import React, { useEffect, useState, useRef } from "react";
import { Button, Space, Table, Drawer, Layout, Input, Typography, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { queryLeadsWithFilters } from "./utils/dataHooks";
import dayjs from "dayjs";
import FormLead_admin from "./Form_admin";
import Notes from "./Notes";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Auth } from "aws-amplify";

const ListLeads_inReview = () => {
  const [open, setOpen] = useState(false);
  const [containeddata, setContainedData] = useState({});
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const { Title } = Typography;
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    fetchData();
  }, [loaded]);

  const handleChange = (pagination, filters, sorter) => {
    if (!loaded) {
      fetchData();
    }
    setPageSize(pagination.pageSize);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const showDrawer = (record) => {
    setOpen(true);
    setContainedData(
      data.filter((lead) => (lead.id === record.id ? lead : null))[0]
    );
  };

  const onClose = () => {
    setOpen(false);
    setContainedData({});
  };

  const fetchData = async (token = null, limit = 20) => {
    
    const leadsData = await queryLeadsWithFilters( setData, {status : {eq: "In Review"}});
    console.log(leadsData)
    // setData(leadsData.filter((item) => item.status === "In Review"));
    setLoaded(true);
  };

  if (!loaded) {
    return <Spin />;
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (
        record[dataIndex[0]] != null &&
        record[dataIndex[0]][dataIndex[1]] != null
      )
        return record[dataIndex[0]][dataIndex[1]]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Company Name",
      dataIndex: ["client", "name"],
      key: "client",
      ...getColumnSearchProps(["client", "name"]),
    },
    {
      title: "Owner name",
      dataIndex: ["client", "referentName"],
      key: "owner",
      ellipsis: true,
      ...getColumnSearchProps(["client", "referentName"]),
    },
    {
      title: "Phone 1",
      dataIndex: ["client", "id"],
      key: "phone",
      ellipsis: true,
      ...getColumnSearchProps(["client", "id"]),
    },
    // {
    //   title: "Phone 2",
    //   dataIndex: ["client", "phone"],
    //   key: "phone2",
    //   ellipsis: true,
    //   ...getColumnSearchProps(["client", "phone"]),
    // },
    // {
    //   title: "Email",
    //   dataIndex: ["client", "email"],
    //   key: "email",
    //   ellipsis: true,
    //   ...getColumnSearchProps(["client", "email"]),
    // },
    // {
    //   title: "Processor",
    //   dataIndex: ["provider", "name"],
    //   key: "provider",
    //   ellipsis: true,
    //   ...getColumnSearchProps(["provider", "name"]),
    // },
    {
      title: "Opener name",
      dataIndex: ["opener", "name"],
      key: "opener",
      ellipsis: true,
      ...getColumnSearchProps(["opener", "name"]),
    },
    {
      title: "Closer name",
      dataIndex: ["closer", "name"],
      key: "closer",
      ellipsis: true,
      ...getColumnSearchProps(["closer", "name"]),
    },
    // {
    //   title: "Creation Date",
    //   dataIndex: ["createdAt"],
    //   key: "creationdate",
    //   ellipsis: true,
    //   render: (a) => dayjs(a).format("DD/MM/YY hh:mm A"),
    //   sorter: (a, b) => {
    //     return dayjs(b.createdAt).diff(a.createdAt);
    //   },
    //   sortOrder:
    //     sortedInfo.columnKey === "creationdate" ? sortedInfo.order : null,
    // },
    // {
    //   title: "Last Update",
    //   dataIndex: ["updatedAt"],
    //   key: "updateddate",
    //   render: (a) => dayjs(a).format("DD/MM/YY hh:mm A"),
    //   ellipsis: true,
    //   sorter: (a, b) => {
    //     return dayjs(b.updatedAt).diff(a.updatedAt);
    //   },
    //   sortOrder:
    //     sortedInfo.columnKey === "updateddate" ? sortedInfo.order : null,
    // },
    {
      title: "Next callBack",
      dataIndex: ["event", "eventDate"],
      key: "eventdate",
      render: (a) => (a ? dayjs(a).format("DD/MM/YY hh:mm A") : null),
      ellipsis: true,
      sorter: (a, b) => {
        if (a && a.event?.eventDate && b && b.event?.eventDate) {
          return dayjs(b.event.eventDate).diff(a.event.eventDate);
        } else if (a && a.event?.eventDate) {
          return -1;
        } else {
          return 1;
        }
      },
      sortOrder: sortedInfo.columnKey === "eventdate" ? sortedInfo.order : null,
    },
    {
      title: "Transfer Type",
      dataIndex: ["transferType"],
      key: "transfer",
      ellipsis: true,
      filterSearch: true,
    },
    {
      title: "Lead Status",
      dataIndex: ["status"],
      key: "leadStatus",
      ellipsis: true,
      filterSearch: true,
    },
  ];

  data.map((record, index) => {
    // record.key = index;
    record.key = record.id;
  });

  return (
    <>
      <div>
        <Title level={2}> All Leads </Title>
        <Table
          columns={columns}
          dataSource={data}
          onChange={handleChange}
          pagination={{ pageSize: pageSize }}
          // click row
          onRow={(record, rowIndex) => {
            return {
              onClick: (event, data) => {
                showDrawer(record);
              },
            };
          }}
        />

        <Drawer
          title={containeddata.name}
          placement="right"
          closable={false}
          onClose={onClose}
          open={open}
          width={800}
          destroyOnClose={true}
        >
          <Layout>
            <Content>
              <FormLead_admin
                
                leadInfo={{ lead: containeddata }}
                setContainedData={setContainedData}
                loaded={loaded}
                setLoaded={setLoaded}
                setOpen={onClose}
              />
            </Content>
            <Sider
              width={250}
              style={{
                background: "white",
              }}
            >
              <Notes leadInfo={{ lead: containeddata }} />
            </Sider>
          </Layout>
        </Drawer>
      </div>
    </>
  );
};
export default ListLeads_inReview;
