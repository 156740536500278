import React, { useEffect, useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Avatar, Form, Divider, Input, List, Skeleton, Button, Space } from 'antd';
import { Auth } from 'aws-amplify';

import {queryNotes, mutationCreateNote} from './utils/dataHooks'
import dayjs from 'dayjs';

const Notes = (leadInfo) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [notes, SetNotes] = useState([]);
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const { TextArea } = Input;
  const [user, setUser] = useState(null);

  useEffect(() => {

    fetchData();
  },[leadInfo]);

  const fetchData = async () => {
    const leadId = leadInfo.leadInfo?.lead?.id ? leadInfo.leadInfo?.lead?.id : leadInfo.leadId ;
    if (leadId) {
      await Auth.currentAuthenticatedUser().then((user) => {
        setUser(user.attributes.name);
        queryNotes(leadId).then(
          (a) => {
            SetNotes(a);
            setData([...a]);
            setLoading(false);
            Auth.currentAuthenticatedUser().then();
          });
      });
    }
  }

  const loadMoreData = () => {
    if (loading) {
      return;
    }
  };

  const onClick = () => {
    if (form.getFieldValue().note){
      mutationCreateNote(leadInfo.leadInfo.lead.id, form.getFieldValue().note);
      setData([{id: Math.random(), user: {name: user}, updatedAt: dayjs().format("MM/DD/YYYY hh:mm A"), note: form.getFieldValue().note}, ...data]);
      form.resetFields();
    }
  }

  const handleKeyPress = (key) => {
    if (key.key === "Enter" && !key.shiftKey){
      //setData([...data, ]);
    }
  }
  return (
    <><div
      id="scrollableDiv"
      style={{
        height: 400,
        overflow: 'auto',
        padding: '0 16px',
        border: '1px solid rgba(140, 140, 140, 0.35)',
      }}
    >
      <InfiniteScroll
        dataLength={data.length}
        next={loadMoreData}
        hasMore={ data.length > 3 ? data.length < 3 : false}
        loader={ data.length !== 0 ? <Skeleton
          avatar
          paragraph={{
            rows: 1,
          }}
          active /> : null}
        endMessage={<Divider plain>No more notes.</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={data}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={item.user.name + ' ' + dayjs(item.updatedAt).format("DD/MM/YYYY hh:mm A")}
                description={item.note} />
            </List.Item>
          )} />
      </InfiniteScroll>
    </div>
    <div>
      <Form
        form={form}
        name="note-hooks"
      >
        <Form.Item
          name={"note"}
        >
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <TextArea rows={4} onKeyDown={handleKeyPress} />

        <Button type="primary" htmlType="submit" style={{ padding: '0 8px' }} onClick={onClick}>
            Add Note
        </Button>
        </Space>
        </Form.Item>
      </Form>
    </div>
    </>
  );
};
export default Notes;
