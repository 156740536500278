import { Auth, API } from 'aws-amplify';
import { createLead, updateLead, createEventCalendar, createNote, updateEventCalendar, updateUser } from '../graphql/custom_mutations';
import { getEventCalendar, getLeadCount, getClient, leadsByOpenerIdAndCreatedAt, listLeads, listProviders, eventCalendarsByLeadIdAndEventDate, eventCalendarsByUserIdAndEventDate, notesByLeadIdAndCreatedAt, listUsers, getUser, leadsByClientIdAndCreatedAt } from '../graphql/custom_queries';
import { UserManagement, } from '../graphql/mutations';
import { updateClient, createClient, createUser, deleteEventCalendar } from '../graphql/custom_mutations';

import { listUsersStatuses } from '../graphql/custom_queries';
export const sendErrorToLambda = async (error, additionalInfo) => {
  const apiName = 'apiLogErrorsCloudWatch'; 
  const path = "/writeToCloudWatch"
  const errorMessage = {
    error: {
      message: error.errors.map((e) => e.message).join('; '), // Concatenate all error messages if there are multiple
      locations: error.errors.map((e) => e.locations), // Include error locations
      path: error.errors.map((e) => e.path), // Include error paths
      additionalInfo: additionalInfo // Include any additional information passed to the function
    }
  };
  
  try {
    await API.post(apiName, path, { body: errorMessage });
  } catch (networkError) {
    console.error('Failed to send error log:', networkError);
  }
};


export const queryLeadCount = async () => {
    const result = await Auth.currentAuthenticatedUser().then(
        () => {
            return API.graphql({ query: getLeadCount, variables: { id: "Count" }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
                (err) => {
                    console.log(err);
                    sendErrorToLambda(err, 
                      {
                      functionName: "getLeadCount",
                      inputs: { id: "Count" },
                    });

                }
            )
        });
    return result;
}

export const queryClient = async (clientId, setData=null) => {
    if (clientId === null){
        return null;
    }
    const result = await Auth.currentAuthenticatedUser().then(
        async () => {
            
            return await API.graphql({ query: leadsByClientIdAndCreatedAt, variables: { clientId: clientId, filter: { deleted: { ne: true }, closed: { ne: true } } }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
                (err) => {
                    console.log(err);
                    sendErrorToLambda(err, 
                      {
                      queryName: "leadsByClientIdAndCreatedAt",
                      inputs: { clientId: clientId },
                      mes: "function: queryClient"
                    });
                }
            )
        });
    if (setData) {
        await setData(result?.data?.leadsByClientIdAndCreatedAt?.items.length !== 0);
    }
    return result?.data?.leadsByClientIdAndCreatedAt?.items;
}

export const checkClient = async (clientId) => {
    if (clientId === null) {
      return null;
    }
    const result = await Auth.currentAuthenticatedUser().then(async () => {
      return await API.graphql({
        query: leadsByClientIdAndCreatedAt,
        variables: {
          clientId: clientId,
          filter: { deleted: { ne: true }, closed: { ne: true } },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).catch((err) => {
        console.log(err);
        sendErrorToLambda(err, 
          {
          queryName: "leadsByClientIdAndCreatedAt",
          inputs: { clientId: clientId },
          mes: "function: checkClient",
        });

      });
    });
    const check = result?.data?.leadsByClientIdAndCreatedAt?.items.length !== 0;
  
    let clientData = null;
  
    if (check) {
      const resultGetClient = await API.graphql({
        query: getClient,
        variables: { id: clientId },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).catch((err) => {
        //  if there is no client AWS sends an error. We should return clientData only if Client exists
      });
      clientData = resultGetClient?.data?.getClient;
    }
    return { check, clientData };
  };
  

const updateCountLead = async () => {
    // Function to update the lead count in the database and returns tne new count.
    // It uses optimistic concurrency control to handle concurrent updates.
    // If the update fails, it retries up to 10 times.
  
    var count = await queryLeadCount();
    var version = count.data?.getLead._version;
    var leadsCount = count.data?.getLead.leadsCount;
    var updatedAt = count.data?.getLead.updatedAt;
    var check = false;
    var retry = 0;
  
    while (!check && retry < 10) {
      const result = await API.graphql({
        query: updateLead,
        variables: {
          input: { id: "Count", _version: version, leadsCount: leadsCount + 1 },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).catch((err) => {
        console.log("query: updateLead", err);
        sendErrorToLambda(err, 
          {
          queryName: "updateLead",
          mes: "function: updateCountLead"
        });
      });
      check =
        result.data?.updateLead.updatedAt === updatedAt ||
        result.data?.updateLead._version !== version + 1
          ? false
          : true;
      if (!check) {
        count = await queryLeadCount();
        version = count.data?.getLead._version;
        leadsCount = count.data?.getLead.leadsCount;
        updatedAt = result.data?.updateLead.updatedAt;
        await delay(300); 
        retry++;
      }
    }
    if (retry === 10) {
      throw new Error("Database is overloaded, try again later");
    }
    // returns id of the next lead
    return leadsCount + 1;
  };

  

  export const handleDuplicateRequest = async (formData) => {
    const user = await Auth.currentAuthenticatedUser();
  
    // Construct the client input based on the provided form data
    let inputClient = {
      id: formData.tel,
      name: formData.entreprise,
      isCompany: true,
    };
  
    if (formData.email) {
      inputClient.email = formData.email;
    }
  
    if (formData.tel2) {
      inputClient.phone = formData.tel2;
    }
  
    if (formData.nom) {
      inputClient.referentName = formData.nom;
    }
  
    // Retrieve the existing client's information using the phone number as the ID
    const resultGetClient = await API.graphql({
      query: getClient,
      variables: { id: inputClient.id },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }).catch((err) => {
      console.log("resultGetClient", err);
      sendErrorToLambda(err, 
        {
        queryName: "getClient",
        inputs: { id: inputClient.id },
        mes: "function: handleDuplicateRequest"
      });
      throw err;
    });
    // console.log("resultGetClient", resultGetClient);
    // If no client exists with the provided phone number, throw an error, what do we do?
    if (!resultGetClient?.data?.getClient) {
      throw new Error("Requested Duped when there is no such client in DataBase");
    }
  
    // Prepare the input for the createLead query
    let idLead;
    try {
      idLead = await updateCountLead();
    } catch (err) {
      console.log("updateCountLead()", err);
      sendErrorToLambda(err, 
        {mes: "function: handleDuplicateRequest(). updateCountLead() It is not possible to obtain idLead"
      });
      throw err;
    }
    const inputLead = {
      id: idLead,
      openerId: user.username,
      clientId: inputClient.id,
      isVerified: false,
      closed: false,
      transferType: "Request Dupe",
    };
  
    if (formData.fournisseur) {
      inputLead.providerId = formData.fournisseur;
    }
  
    if (formData.pos) {
      inputLead.pos = formData.pos;
    }
    if (formData.score) {
      inputLead.score = formData.score;
    }
  
    // Create a new lead entry in the database
    const resultCreateLead = await API.graphql({
      query: createLead,
      variables: { input: inputLead },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }).catch((err) => {
      console.log("resultCreateLead", err);
      sendErrorToLambda(err, 
        {
        queryName: "createLead",
        inputs: { input: inputLead },
        mes: "function: handleDuplicateRequest(). resultCreateLead"
      });
      throw err;
    });
    const leadData = resultCreateLead.data?.createLead;
  
    // Return the created lead's information
    return leadData;
  };
  
  export const handleCreateLead = async (
    formData,
    isCallback = false,
    transferType = null
  ) => {
    // Function to handle the creation of a new lead.
    // It first creates a new client entry in the database based on the provided form data.
    // Then, it prepares the input for the createLead query and creates a new lead entry.
    // If the isCallback flag is true, it also creates a new callback and updates the lead with the callback's event ID.
    const user = await Auth.currentAuthenticatedUser();
    // Construct the client input based on the provided form data
    let inputClient = {
      id: formData.tel,
      name: formData.entreprise,
      isCompany: true,
    };
  
    if (formData.email) {
      inputClient.email = formData.email;
    }
  
    if (formData.tel2) {
      inputClient.phone = formData.tel2;
    }
  
    if (formData.nom) {
      inputClient.referentName = formData.nom;
    }
    if (formData.language) {
      inputClient.language = formData.language;
    }
    

    // Create a new client entry in the database
    const client = await API.graphql({
      query: createClient,
      variables: {
        input: inputClient,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }).catch((err) => {
      console.log("creating a client error", err);
      sendErrorToLambda(err, 
        {
        queryName: "createClient",
        inputs: {
          input: inputClient,
        },
        mes: "function: handleCreateLead(). client"
      });
      throw err;
    });
  
    // Prepare the input for the createLead query
    let idLead;
    try {
      idLead = await updateCountLead();
    } catch (err) {
      console.log("updateCountLead()", err);
      sendErrorToLambda(err, 
        {mes: "function: handleCreateLead(). updateCountLead() It is not possible to obtain idLead"
      });

      throw err;
    }
    // console.log("idLead", idLead);
  
    const inputLead = {
      id: idLead,
      openerId: user.username,
      clientId: inputClient.id,
      isVerified: true,
      closed: false,
    };
  
    if (formData.fournisseur) {
      inputLead.providerId = formData.fournisseur;
    }
  
    if (formData.pos) {
      inputLead.pos = formData.pos;
    }
    if (transferType) {
      inputLead.transferType = transferType;
      inputLead.transferDate = new Date();
      if (transferType === "Live"){
        inputLead.status = "Assigned";
      } else if(transferType === "Warm"){
        inputLead.status = "Ready for assignment";
      }
      inputLead.statusChangeDate = new Date();
    }
    if (formData.closer) {
      inputLead.closerId = formData.closer;
    }
    if (formData.score) {
      inputLead.score = formData.score;
    }
    // Create a new lead entry in the database
    const resultCreateLead = await API.graphql({
      query: createLead,
      variables: { input: inputLead },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }).catch((err) => {
      console.log("resultCreateLead", err);
      sendErrorToLambda(err, 
        {
        queryName: "createLead",
        inputs: {
          input: { input: inputLead },
        },
        mes: "function: handleCreateLead(). resultCreateLead"
      })
      throw err;
    });
    const leadData = resultCreateLead.data?.createLead;
  
    // If the isCallback flag is set, create a new callback and update the lead with the callback's event ID
    if (isCallback) {
      const callback = await createCallback(leadData, formData.callbackDate);
      const resultUpdateLead = await API.graphql({
        query: updateLead,
        variables: {
          input: {
            id: leadData.id,
            eventId: callback.data?.createEventCalendar.id,
            _version: leadData._version,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).catch((err) => {
        console.log("updateLead error", err);
        sendErrorToLambda(err, 
          {
          queryName: "updateLead",
          inputs: {
            input: {input: {
              id: leadData.id,
              eventId: callback.data?.createEventCalendar.id,
              _version: leadData._version,
            }},
          },
          mes: "function: handleCreateLead(). resultUpdateLead, adding a calback to lead"
        });
        throw err;
      });
  
      return resultUpdateLead.data?.updateLead;
    }
  
    return leadData;
  };
  

export function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const fetchWithPagination = async (query, variables, key, retryCount = 0) => {
    let result = await API.graphql({
      query,
      variables,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }).catch((err) => {
      console.log(err);
      const queryNameMatch = query.match(/\bquery\s+(\w+)/);
      const queryName = queryNameMatch ? queryNameMatch[1] : 'UnknownQuery';
      sendErrorToLambda(err, 
        {
        queryName: queryName,
        inputs: variables,
        mes: `Got undefined result. Attempt ${retryCount + 1}.`
      });

    });
  
    // If result is undefined and we haven't retried 3 times yet, retry
    if (!result && retryCount < 2) {
      console.log(`Got undefined result. Attempt ${retryCount + 1}.`);
      await delay(1000); // 1 second delay
      return fetchWithPagination(query, variables, key, retryCount + 1);
    }
  
    let items = result?.data?.[key]?.items || [];
    let token = result?.data?.[key]?.nextToken;
  
    while (token) {
      variables.nextToken = token;
      const newData = await API.graphql({
        query,
        variables,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).catch((err) => {
        console.log(err);
        const queryNameMatch = query.match(/\bquery\s+(\w+)/);
        const queryName = queryNameMatch ? queryNameMatch[1] : 'UnknownQuery';
        sendErrorToLambda(err, 
          {
          queryName: queryName,
          inputs: variables,
          mes: "Parsing next token inside \"fetchWithPagination\""
        })

      });
      items = items.concat(newData?.data?.[key]?.items || []);
      token = newData?.data?.[key]?.nextToken;
    }
  
    return items;
  };

//   we use it for List Opener
  export const queryLeadsByUserAsOpener = async (
    setState,
    userId,
    ) => {
            const resultItems = await fetchWithPagination(
              leadsByOpenerIdAndCreatedAt,
              { openerId: userId },
              "leadsByOpenerIdAndCreatedAt"
            );
            const processedLeads = resultItems.map(lead => {
              if (lead?.event && lead?.event?._deleted) {
                  lead.event = null;
                  lead.eventId = null;
              }
              return lead;
          });

          setState(processedLeads);
          return processedLeads;
            // setState(resultItems);
            // return resultItems;
    }

//only admin have acces, it calls all leads, (Admin's WarmList, DupeList)
export const queryLeadsWithFilters = async (
    setState,
    { transferType = null, closed = null, closerId = null, status = null } = {},
    ) => {
        const user = await Auth.currentAuthenticatedUser();
        const isAdmin =
        user?.signInUserSession?.idToken?.payload?.["cognito:groups"]?.[0] ===
        "Admin";
        const input = { filter: { id: { ne: "Count" }, deleted: { ne: true } } };
        if (transferType) input.filter.transferType = transferType;
        if (closed) input.filter.closed = closed;
        if (closerId) input.filter.closerId = closerId;
        if (status) input.filter.status = status;
        if (isAdmin) {
            const resultItems = await fetchWithPagination(
              listLeads,
              input,
              "listLeads"
            );
            setState(resultItems);
            return resultItems;
    }
}

//it fetches leads by current user as a closer, hides deleted users
// export const queryLeadsByUserAsCloser = async (setState) => {

//     const leads = await Auth.currentAuthenticatedUser().then(
//         async (user) => {

//             const userId = user.signInUserSession.idToken.payload["sub"]
//             var result = await API.graphql({ 
//                 query: listLeadsCloser, 
//                 variables:  {
//                     filter: {
//                         closerId: { eq: userId },
//                         deleted: { ne: true }
//                     }
//                 }, 
//                 authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
//                     (err) => {
//                         console.log(JSON.stringify(err, null, 2));

//                         console.log(err);
//                         sendErrorToLambda(err, 
//                           {
//                           queryName: "listLeadsCloser",
//                           inputs: {
//                             closerId: { eq: userId },
//                             deleted: { ne: true }
//                         },
//                           mes: "function queryLeadsByUserAsCloser"
//                         });
//                     }
//             );
            
//             if (result && result.data?.listLeads.nextToken){
//                 let token = result.data?.listLeads.nextToken;
//                 while(token){
//                     const newData = await API.graphql({ 
//                         query: listLeadsCloser, 
//                         variables: { 
//                             filter: {
//                                 closerId: {eq : userId},
//                                 deleted: { ne: true }
//                             },
//                             nextToken: token 
//                         }, 
//                         authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
//                         (err) => {
//                             console.log(err);
//                             sendErrorToLambda(err, 
//                               {
//                               queryName: "listLeadsCloser",
//                               inputs: {
//                                 closerId: { eq: userId },
//                                 deleted: { ne: true },
//                                 nextToken: token
//                             },
//                               mes: "function queryLeadsByUserAsCloser, by token"
//                             });
//                         }
                    
//                     );
//                     token = newData.data?.listLeads?.nextToken;
//                     result.data?.listLeads.items.push(...newData.data?.listLeads.items);
//                     result.data.listLeads.nextToken = token;
//                 }
//             }

//             const processedLeads = result.data?.listLeads.items.map(lead => {
//               if (lead?.event && lead?.event?._deleted) {
//                   lead.event = null;
//                   lead.eventId = null;
//               }
//               return lead;
//           });

//           setState(processedLeads);
//           return processedLeads;
//         });

//         return  leads;
// }

export const queryLeadsByUserAsCloser = async (setState) => {
  const user = await Auth.currentAuthenticatedUser();
  const userId = user.signInUserSession.idToken.payload["sub"];

  const variables = {
      filter: {
          closerId: { eq: userId },
          deleted: { ne: true }
      }
  };
  // listLeadsCloser
  // const leads = await fetchWithPagination(listLeads, variables, 'listLeads');
  const leads = await fetchWithPagination(listLeads, variables, 'listLeads');

  // Process each lead to set event and eventId to null if event is deleted
  const processedLeads = leads.map(lead => {
      if (lead?.event && lead?.event?._deleted) {
          lead.event = null;
          lead.eventId = null;
      }
      return lead;
  });

  setState(processedLeads);
  return processedLeads;
};



// export const queryLeadsAllByChanks = async (nextToken = null, limit_items_per_chunk = 10) => {

//     const leads = await Auth.currentAuthenticatedUser().then(
//             async (user) => {
//                 const result = await API.graphql({ 
//                 query: listLeads, 
//                 variables: { 
//                     limit: limit_items_per_chunk, 
//                     nextToken: nextToken 
//                 }, 
//                 authMode: "AMAZON_COGNITO_USER_POOLS" 
//                 }).catch(
//                     (err) => {
//                         console.log(JSON.stringify(err, null, 2));

//                         console.log(err);
//                     });
//                 return result.data?.listLeads;
//     })
//     return leads
//   }

  // export const queryLeadsAll= async (setState = null,) => {

  //   const leads = await Auth.currentAuthenticatedUser().then(
  //           async (user) => {
  //               const input = { filter: {id: {ne: "Count"}, deleted: {ne: true} } };
  //               const result = await API.graphql({ 
  //               query: listLeads, 
  //               variables: { 
  //                   input
  //               }, 
  //               authMode: "AMAZON_COGNITO_USER_POOLS" 
  //               }).catch(
  //                   (err) => {
  //                       console.log(JSON.stringify(err, null, 2));

  //                       console.log(err);
  //                   });
                

  //               if (result.data?.listLeads.nextToken){
  //                   var token = result.data?.listLeads.nextToken;
  //                   input.nextToken = token;
  //                   while(token !== null){
  //                       const newData = await API.graphql({ 
  //                           query: listLeads, 
  //                           variables: input, 
  //                           authMode: "AMAZON_COGNITO_USER_POOLS" 
  //                       }).catch(
  //                           (err) => {
  //                               console.log(err);
  //                           }
  //                       );
  //                       token = newData.data?.listLeads.nextToken;
  //                       result.data?.listLeads.items.push(...newData.data?.listLeads.items);
  //                       result.data.listLeads.nextToken = token;
  //                   }
  //               }
  //               if (setState){setState(result.data?.listLeads.items);}
  //               return result.data?.listLeads.items;
  //   })
  //   return leads
  // }
  

export const createCallback = async (data, date=null) => {
    var eventDate;
    if(date){
        eventDate = new Date(date);
    }
    else{
        eventDate = new Date(data.createdAt);
    }
    var callback = new Date(eventDate);
    // callback = new Date(callback.setMinutes(eventDate.getMinutes()-15))
    const result = Auth.currentAuthenticatedUser().then(async (user) => {
        return await API.graphql({ query: createEventCalendar, variables: { input: { eventDate: eventDate, type:"Callback", callbackDate: callback, userId:user.username, leadId: data.id } }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
            (err) => {
                console.log(err);
                sendErrorToLambda(err, 
                  {
                  queryName: "createEventCalendar",
                  inputs:  { input: { eventDate: eventDate, type:"Callback", callbackDate: callback, userId:user.username, leadId: data.id } },
                  mes: "function createCallback"
                });
            }
        )
    });
    return result;
}

export const mutationCreateNote = async (leadId, noteText) => {
    const note = Auth.currentAuthenticatedUser().then( async (user) => {
        return await API.graphql({ query: createNote, variables: { input: { leadId: leadId, owner: user.username, note: noteText } }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
            (err) => {
                console.log(err);
                sendErrorToLambda(err, 
                  {
                  queryName: "createNote",
                  inputs:  { input: { leadId: leadId, owner: user.username, note: noteText } },
                  mes: "function mutationCreateNote"
                });

            }
        );
    });
    return note.data?.createNote;
}

export const transferLead = async (id, transferType) => {
    return await API.graphql({ query: updateLead, variables: { input: { id: id, transferType: transferType } }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
            (err) => {
                console.log(err);
                sendErrorToLambda(err, 
                  {
                  queryName: "updateLead",
                  inputs:   { input: { id: id, transferType: transferType } },
                  mes: "function transferLead"
                });
            }
        );
}

export const queryEventsCalendar = async (startDate = null, endDate = null, setData = null) => {
  const user = await Auth.currentAuthenticatedUser().catch(err => {
      console.log(err);
      sendErrorToLambda(err, {
          queryName: "eventCalendarsByUserIdAndEventDate",
          inputs: { userId: user.username, eventDate: { between: [startDate, endDate] } },
          mes: "Getting current authenticated user in queryEventsCalendar"
      });
  });

  if (!user) return [];

  const variables = {
      userId: user.username,
      eventDate: { between: [startDate, endDate] },
      filter: { _deleted: { ne: true } },
      sortDirection: "ASC",
  };

  const events = await fetchWithPagination(eventCalendarsByUserIdAndEventDate, variables, "eventCalendarsByUserIdAndEventDate");

  if (setData) {
      await setData(events);
  }

  return events;
};


export const queryNotes = async (leadId) => {
  await Auth.currentAuthenticatedUser().catch(err => {
      console.log(err);
      sendErrorToLambda(err, {
          queryName: "notesByLeadIdAndCreatedAt",
          inputs: { leadId: leadId, sortDirection: "DESC" },
          mes: "Getting current authenticated user in queryNotes"
      });
      return [];
  });

  const variables = {
      leadId: leadId,
      sortDirection: "DESC"
  };

  return await fetchWithPagination(notesByLeadIdAndCreatedAt, variables, "notesByLeadIdAndCreatedAt");
};


export const updateLeadStatus = async (
    leadInfo,
    status,
  ) => {
    console.log("updateLeadStatus, leadInfo",leadInfo, "status",status)
    const lead = await Auth.currentAuthenticatedUser().then(async () => {
      const lead_db = await API.graphql({ query: getLeadCount, variables: {  id: leadInfo.lead.id }, authMode:"AMAZON_COGNITO_USER_POOLS" }).catch(
        (err) => {
            console.log(err);
            console.log(err, 
              {
              queryName: "getLead",
              inputs:   { input: { id: leadInfo.lead.id} },
              mes: "function updateLeadStatus"
            });
        }
    ); 
      const input = {
        id: leadInfo.lead.id,
        _version: lead_db.data.getLead._version,
        status: status, 
        statusChangeDate: new Date(),
        transferDate: new Date(),
      };
      const deleteEventCalendarStatuses = [
        "Not interested",
        "Assigned",
        "Client",
      ]
      // const eventId = leadInfo.lead?.event?.id ?? leadInfo.lead?.eventId
      const eventId = leadInfo.lead?.event?.id ?? leadInfo.lead?.eventId ?? leadInfo?.id

      if (deleteEventCalendarStatuses.includes(status) && eventId){
        input.eventId = null
        const event_db = await API.graphql({ query: getEventCalendar, variables: { id: eventId}, authMode:"AMAZON_COGNITO_USER_POOLS" }).catch(
            (err) => {
                console.log(err);
                sendErrorToLambda(err, 
                  {
                  queryName: "getEventCalendar",
                  inputs:   {  id: eventId },
                  mes: "function updateLeadStatus"
                });
            }
        );

        const event = await API.graphql({ query: deleteEventCalendar, variables: { input: { id: eventId, _version: event_db.data.getEventCalendar._version } }, authMode:"AMAZON_COGNITO_USER_POOLS" }).catch(
            (err) => {
                console.log(err);
                sendErrorToLambda(err, 
                  {
                  queryName: "deleteEventCalendar",
                  inputs:   { input: { id: eventId, _version: event_db.data.getEventCalendar._version } },
                  mes: "function updateLeadStatus"
                });
            }
        );
  
      }
  
      return await API.graphql({
        query: updateLead,
        variables: { input: input },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).catch((err) => {
        console.log(err);
        sendErrorToLambda(err, 
          {
          queryName: "updateLead",
          inputs:   { input: input },
          mes: "function updateLeadStatus"
        });
      });
    });

    return lead.data?.updateLead;
  };

export const saveLead = async (leadInfo, updatedData, setData=null, setLoaded=null) => {
  console.log("saveLead leadInfo",leadInfo, "updatedData",updatedData )
    const lead = await Auth.currentAuthenticatedUser().then(
        async () => {
            // we include only those that have some value, with type 'null' cognito will not allow to modify it
            const lead_input = {
                id: leadInfo.lead.id,
                _version: leadInfo.lead._version,
                ...(updatedData.fournisseur ? { providerId: updatedData.fournisseur } : {providerId: " "}),
                ...(updatedData.pos ? { pos: updatedData.pos } : {pos: " "}),
                ...(updatedData.score ? { score: updatedData.score } : {score: " "}),
              };
            const lead = await API.graphql({ query: updateLead, variables: { input: lead_input}, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
                (err) => {
                    console.log(err);
                    sendErrorToLambda(err, 
                      {
                      queryName: "updateLead",
                      inputs:   { input: lead_input},
                      mes: "function saveLead"
                    });
                }
            );
            
            // we include only those that have some value, with type 'null' cognito will not allow to modify it
            const client_input = {
                id: leadInfo.lead.client.id,
                _version: leadInfo.lead.client._version,
                ...(updatedData.nom ? { referentName: updatedData.nom } : {referentName: " "}),
                ...(updatedData.entreprise ? { name: updatedData.entreprise } : {name: " "}),
                ...(updatedData.tel2 ? { phone: updatedData.tel2 } : {phone: " "}),
                ...(updatedData.email ? { email: updatedData.email } : {email: " "}),
                ...(updatedData.language ? { language: updatedData.language } : {language: " "}),
              };
            const client = await API.graphql({ query: updateClient, variables: { input: client_input  }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
                (err) => {
                    console.log(err);
                    sendErrorToLambda(err, 
                      {
                      queryName: "updateClient",
                      inputs:   { input: client_input  },
                      mes: "function saveLead"
                    });
                }
            );
            //Horrible, but there are different datastrustures, we need to rewrite it with explicitly calling for openerId in input
            const openerId = leadInfo.lead.openerId ?? leadInfo.lead.opener.id
            const opener = await API.graphql({ query: getUser, variables: { id:openerId  }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
                (err) => {
                    console.log(err);
                    sendErrorToLambda(err, 
                      {
                      queryName: "getUser",
                      inputs:   { id:leadInfo.lead.openerId  },
                      mes: "function saveLead"
                    });
                }
            );
            return [lead, client, opener];
        }
    );
    leadInfo.lead = lead[0].data?.updateLead;
    leadInfo.lead.client = lead[1].data?.updateClient;
    leadInfo.lead.opener = lead[2].data?.getUser
    if (setData){
        setData(leadInfo.lead);
    }
    if (setLoaded){
        setLoaded(false);
    }
    
    return lead.data?.updateClient;
}

export const deleteLead = async (leadInfo, setLoaded=null) => {
  const inputLead = {
    id: leadInfo.lead.id, 
    _version: leadInfo.lead._version, 
    deleted: true, 
    transferType: "Delete", 
    transferDate: new Date() 
  }
    const lead = await Auth.currentAuthenticatedUser().then(
        async () => {
            return await API.graphql({ query: updateLead, variables: { input: inputLead }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
                (err) => {
                    console.log("deleteLead", err, "input: leadInfo", leadInfo,  "input: setLoaded", setLoaded);
                    sendErrorToLambda(err, 
                      {
                      queryName: "updateLead",
                      inputs:  { input: inputLead },
                      mes: "function deleteLead"
                    });
                }
            );
        }
    );

    if (leadInfo.lead?.event?.id){
      const inputEvent = { 
        id: leadInfo.lead.event.id, 
        _version: leadInfo.lead.event._version 
      }

      const event = await API.graphql({ query: deleteEventCalendar, variables: { input: inputEvent }, authMode:"AMAZON_COGNITO_USER_POOLS" }).catch(
          (err) => {
              console.log("deleteLead", err, "input: leadInfo", leadInfo,  "input: setLoaded", setLoaded);
              sendErrorToLambda(err, 
                {
                queryName: "deleteEventCalendar",
                inputs:   { input: inputEvent },
                mes: "function deleteLead"
              });
          }
      );
    }
    if (setLoaded){
        setLoaded(false);
    }
    return lead.data?.updateLead;
}

// even if we have a field deleted we cannot retrieve information about it if user with id different from owner asks for it
export const updateTransferType = async (leadInfo, transferType, closerId=null) => {
    const lead = await Auth.currentAuthenticatedUser().then(
        async () => {
            const input = { id: leadInfo.lead.id, 
              _version: leadInfo.lead._version, 
              transferType: transferType, 
              transferDate: new Date(),
              eventId: null
            };

            // not sure if it is alligned with best practices - logic of status in this query-like function
            if (transferType === "Live" && closerId !== null){
                input.closerId = closerId;
                input.status = "Assigned"
            }

            if (transferType === "Warm"){
              input.status = "Ready for assignment"
            }

            if (transferType === "Revive"){
              input.status = "Assigned"
            }

            input.statusChangeDate = new Date()

            return await API.graphql({ query: updateLead, variables: { input: input }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
                (err) => {
                    console.log(err);
                    sendErrorToLambda(err, 
                      {
                      queryName: "updateLead",
                      inputs:   { input: input },
                      mes: "function updateTransferType"
                    });
                }
            );
        }
    );
    const eventId = leadInfo.lead?.event?.id
    if (eventId){
        const event_db = await API.graphql({ query: getEventCalendar, variables: { id: eventId}, authMode:"AMAZON_COGNITO_USER_POOLS" }).catch(
          (err) => {
              console.log(err);
              sendErrorToLambda(err, 
                {
                queryName: "getEventCalendar",
                inputs:   {  id: eventId },
                mes: "function updateLeadStatus"
              });
          }
      );
      
        const event = await API.graphql({ query: deleteEventCalendar, variables: { input: { id: eventId, _version: event_db.data.getEventCalendar._version } }, authMode:"AMAZON_COGNITO_USER_POOLS" }).catch(
            (err) => {
                console.log(err);
                sendErrorToLambda(err, 
                  {
                  queryName: "deleteEventCalendar",
                  inputs:   { input: { id: eventId, _version: event_db.data.getEventCalendar._version } },
                  mes: "function updateTransferType"
                });
            }
        );
    }
    return lead.data?.updateLead
}

export const addCallback = async (leadInfo, date=null, setLoaded=null) => {
  console.log("add caalback, leadinfo", leadInfo)
    var eventDate;
    const currentEvent = await Auth.currentAuthenticatedUser().then(async (user) => {
        return await API.graphql({ query: eventCalendarsByLeadIdAndEventDate, variables: { leadId: leadInfo.lead.id, filter: { _deleted: { ne: true } } }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
            (err) => {
                console.log(err);
                sendErrorToLambda(err, 
                  {
                  queryName: "eventCalendarsByLeadIdAndEventDate",
                  inputs:   { leadId: leadInfo.lead.id},
                  mes: "function addCallback"
                });
            }
        );
    });
    if(date){
        eventDate = new Date(date);
    }
    else{
        eventDate = new Date();
    }
    var callback = new Date(eventDate);
    // callback = new Date(callback.setMinutes(eventDate.getMinutes()-15))
    if (currentEvent.data?.eventCalendarsByLeadIdAndEventDate.items.length === 0){
        const result = await Auth.currentAuthenticatedUser().then(async (user) => {
            return await API.graphql({ query: createEventCalendar, variables: { input: { eventDate: eventDate, type:"Callback", callbackDate: callback, userId:user.username, leadId: leadInfo.lead.id } }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
                (err) => {
                    console.log(err);
                    sendErrorToLambda(err, 
                      {
                      queryName: "createEventCalendar",
                      inputs:   { input: { eventDate: eventDate, type:"Callback", callbackDate: callback, userId:user.username, leadId: leadInfo.lead.id }},
                      mes: "function addCallback"
                    });
                }
            )
        });
        if (setLoaded){
            setLoaded(false);
        }
        const updatedLead = await API.graphql({ query: updateLead, variables: { input: { id: leadInfo.lead.id, eventId: result.data?.createEventCalendar.id, _version: leadInfo.lead._version } }, authMode: "AMAZON_COGNITO_USER_POOLS" });
        leadInfo.lead.eventId = updatedLead.data?.updateLead.eventId;
        leadInfo.lead.event = updatedLead.data?.updateLead.event;
        leadInfo.lead._version++;
        return result?.data?.createEventCalendar;
    }
    else {
        const result = await Auth.currentAuthenticatedUser().then(async (user) => {
            return await API.graphql({ query: updateEventCalendar, variables: { input: { id: currentEvent.data?.eventCalendarsByLeadIdAndEventDate.items[0].id, eventDate: eventDate, type:"Callback", callbackDate: callback, userId:user.username, _version: currentEvent.data?.eventCalendarsByLeadIdAndEventDate.items[0]._version } }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
                (err) => {
                    console.log(err);
                    sendErrorToLambda(err, 
                      {
                      queryName: "updateEventCalendar",
                      inputs:   { input: { id: currentEvent.data?.eventCalendarsByLeadIdAndEventDate.items[0].id, eventDate: eventDate, type:"Callback", callbackDate: callback, userId:user.username, _version: currentEvent.data?.eventCalendarsByLeadIdAndEventDate.items[0]._version }},
                      mes: "function addCallback"
                    });
                }
            )
        });
        if (setLoaded){
            setLoaded(false);
        }
        const updatedLead = await API.graphql({ query: updateLead, variables: { input: { id: leadInfo.lead.id, eventId: result.data?.updateEventCalendar.id, _version: leadInfo.lead._version } }, authMode: "AMAZON_COGNITO_USER_POOLS" });
        leadInfo.lead.eventId = updatedLead.data?.updateLead.eventId;
        leadInfo.lead.event = updatedLead.data?.updateLead.event;
        leadInfo.lead._version++;
        return result?.data?.updateEventCalendar;
    }
}

export const getProviders = async () => {
    const result = await Auth.currentAuthenticatedUser().then(
        async () => {
            return await API.graphql({ query: listProviders, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
                (err) => {
                    console.log(err);
                    sendErrorToLambda(err, 
                      {
                      queryName: "listProviders",
                      mes: "function getProviders"
                    });
                }
            );
        }
    )
    return result.data?.listProviders?.items;
}
export const getClosers = async () => {

      
      const variables_closer =  { 
        filter:{ role:{ eq: "Closer" }} 
      }
      const variables_admin =  { 
        filter:{ role:{ eq: "Admin" }} 
      }
 
      const closers_dynamoDB = await fetchWithPagination(listUsers, variables_closer, "listUsers")
      const admins_dynamoDB = await fetchWithPagination(listUsers, variables_admin, "listUsers")

      const users = [...closers_dynamoDB, ...admins_dynamoDB].filter((item)=> item?.status === "disabled" ? false : true)

      return users;
  }
// export const getClosers = async () => {
//     const closers = await API.graphql({ query: listUsers, variables: { filter:{ role:{ eq: "Closer" } } }, authMode:"AMAZON_COGNITO_USER_POOLS" }).catch(
//         (err) => {
//             console.log(err);
//             sendErrorToLambda(err, 
//               {
//               queryName: "listUsers",
//               mes: "function getClosers"
//             });
//         }
//     );
//     if (closers.data?.listUsers.nextToken){
//         var token = closers.data?.listUsers.nextToken;
//         while(token){
//             const newData = await API.graphql({ query: listUsers, variables: { filter:{ role:{ eq: "Closer" } }, nextToken: token }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
//                 (err) => {
//                     console.log(err);
//                     sendErrorToLambda(err, 
//                       {
//                       queryName: "listUsers",
//                       mes: "function getClosers looking for token"
//                     });
//                 }
//             );
//             token = newData.data?.listUsers?.nextToken;
//             closers.data?.listUsers.items.push(...newData.data?.listUsers.items);
//             closers.data.listUsers.nextToken = token;
//         }
//     }
//     return closers.data?.listUsers?.items;
// }

export const warmTransferToCloser = async (leadInfo, closerId, leadsIndices = [], listLeads = null) => {
  const updateLeadForCloser = async (lead) => {
      return API.graphql({ 
          query: updateLead, 
          variables: { input: { id: lead.id, closerId: closerId, _version: lead._version, status: "Assigned", statusChangeDate: new Date()} }, 
          authMode: "AMAZON_COGNITO_USER_POOLS"
      }).catch((err) => {
          console.log(err);
          sendErrorToLambda(err, {
              queryName: "updateLead",
              input: { id: lead.id, closerId: closerId, _version: lead._version },
              mes: "function warmTransferToCloser"
          });
      });
  };

  if (leadsIndices.length === 0) {
      const result = await updateLeadForCloser(leadInfo);
      return result?.data?.updateLead;
  } else {
      const promises = leadsIndices.map(index => updateLeadForCloser(listLeads[index]));
      await Promise.all(promises);
  }
};

export const reengageTransferToCloser = async (leadInfo, closerId, leadsIndices = [], listLeads = null) => {
  const updateLeadForCloser = async (lead) => {
      return API.graphql({ 
          query: updateLead, 
          variables: { input: { id: lead.id, closerId: closerId, _version: lead._version, transferType: "Revive", transferDate:new Date(),  status: "Assigned", statusChangeDate: new Date()} }, 
          authMode: "AMAZON_COGNITO_USER_POOLS"
      }).catch((err) => {
          console.log(err);
          sendErrorToLambda(err, {
              queryName: "updateLead",
              input:  { id: lead.id, closerId: closerId, _version: lead._version, transferType: "Revive", transferDate:new Date(),  status: "Assigned", statusChangeDate: new Date()},
              mes: "function reengageTransferToCloser"
          });
      });
  };

  if (leadsIndices.length === 0) {
      const result = await updateLeadForCloser(leadInfo);
      return result?.data?.updateLead;
  } else {
      const promises = leadsIndices.map(index => updateLeadForCloser(listLeads[index]));
      await Promise.all(promises);
  }
};


// export const warmTransferToCloser = async (leadInfo, closerId, leadsIndices=[], listLeads=null) => {
//     if (leadsIndices.length === 0){
//         const result = await API.graphql({ query: updateLead, variables: { input: { id: leadInfo.id, closerId: closerId, _version: leadInfo._version } }, authMode: "AMAZON_COGNITO_USER_POOLS"}).catch(
//             (err) => {
//                 console.log(err);
//                 sendErrorToLambda(err, 
//                   {
//                   queryName: "updateLead",
//                   input:{ id: leadInfo.id, closerId: closerId, _version: leadInfo._version } ,
//                   mes: "function warmTransferToCloser"
//                 });
//             }
//         );
//         return result.data?.updateLead;
//     }
//     else{
//         leadsIndices.forEach(
//             (index) => {
//                 const lead = listLeads[index];
//                 API.graphql({ query: updateLead, variables: { input: { id: lead.id, closerId: closerId, _version: lead._version } }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
//                     (err) => {
//                         console.log(err);
//                         sendErrorToLambda(err, 
//                           {
//                           queryName: "updateLead",
//                           input:{ id: lead.id, closerId: closerId, _version: lead._version } ,
//                           mes: "function warmTransferToCloser, lead"
//                         });
//                     }
//                 );
//             }
//         );
//     }
// }

export const setVerifiedLead = async (leadInfo, isVerified, setLoaded=null) => {
  const input = {
    id: leadInfo.lead.id, 
    isVerified: isVerified,
    _version: leadInfo.lead._version 
  }
  if (leadInfo.lead?.transferType === "Request Dupe"){
    input.transferType = null
  }
    await API.graphql({ query: updateLead, variables: { input: input }, authMode: "AMAZON_COGNITO_USER_POOLS"}).catch(
        (err) => {
            console.log("setVerifiedLead", err, "input: leadInfo", leadInfo, "input: isVerified", isVerified );
            sendErrorToLambda(err, 
              {
              queryName: "updateLead",
              input:{ input: input } ,
              mes: "function setVerifiedLead"
            });
        }
    );
  if(setLoaded){
    setLoaded(false)
  }
}

export const mutationCreateUser = async (data) => {
    const user = await API.graphql({ query: UserManagement, variables: { event: {task: "CREATE", name: data.username, role: data.group, email: data.email} } }).catch(
        (err) => {
            console.log(err)
            sendErrorToLambda(err, 
              {
              queryName: "UserManagement",
              input: { event: {task: "CREATE", name: data.username, role: data.group, email: data.email} } ,
              mes: "function mutationCreateUser"
            });
        }
    );
    const result = await Auth.currentAuthenticatedUser().then(
        async () => {
            return await API.graphql({ query: createUser, variables: { input: { id: user.data?.UserManagement?.id, name: user.data?.UserManagement.name, role: data.group } } }).catch(
                (err) => {
                    console.log(err);
                    sendErrorToLambda(err, 
                      {
                      queryName: "createUser",
                      input:  { id: user.data?.UserManagement?.id, name: user.data?.UserManagement.name, role: data.group }  ,
                      mes: "function mutationCreateUser"
                    });
                }
            );
        }
    );
    return result.data?.createUser;
}


export const mutationUpdateUser = async (userId, data) => {
    const userData = await API.graphql({ query: getUser, variables: { id: userId }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
        (err) => {
            console.log(err);
            sendErrorToLambda(err, 
              {
              queryName: "getUser",
              input:  { id: userId } ,
              mes: "function mutationUpdateUser"
            });
        }
    );
    data._version = userData.data?.getUser._version;
    const updatedUser = await API.graphql({ query: updateUser, variables: { input: data }, authMode: "AMAZON_COGNITO_USER_POOLS" }).catch(
        (err) => {
            console.log(err);
            sendErrorToLambda(err, 
              {
              queryName: "updateUser",
              input:  { input: data } ,
              mes: "function mutationUpdateUser"
            });
        }
    );
    return updatedUser.data?.updateUser;
}

export const queryEnabledUsers = async () => {
          const resultItems = await fetchWithPagination(
            listUsersStatuses,
            null,
            "listUsers"
          );
        return resultItems.filter((item)=>item.status!=="disabled");

  }