import React, { useState, useEffect } from "react";
import {
  Table,
  Space,
  Drawer,
  Layout,
  Spin,
  Modal,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  queryLeadsWithFilters,
  deleteLead,
  setVerifiedLead,
} from "./utils/dataHooks";
import Notes from "./Notes";

const ListLeadsDuped = () => {
  const [groupedData, setGroupedData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);

  const groupAndFilterLeads = (leads) => {
    const grouped = {};

    leads.forEach((lead) => {
      const { clientId } = lead;
      if (!grouped[clientId]) {
        grouped[clientId] = [];
      }
      grouped[clientId].push(lead);
    });

    return Object.values(grouped)
      .filter(
        (group) =>
          group.length > 1 ||
          // if original was deleted but "request dupe" is ghosted in db, show it
          (group.length === 1 && group[0].transferType === "Request Dupe")
      )
      .map((group) => ({
        // fields for main table (client's info)
        key: group[0].clientId,
        clientId: group[0].clientId,
        companyName: group[0].client?.name,
        phone1: group[0].client?.clientId,
        creationDate: group[0].createdAt,
        ownerName: group[0].client?.referentName,
        phone2: group[0].client?.phone,
        email: group[0].client?.email,

        // fields for the expanded table
        subs: group.map((leadProp) => ({
          key: leadProp.id,
          leadId: leadProp.id,
          // data format for <Notes> component
          leadInfo_notes: {
            lead: {
              id: leadProp.id,
            },
            leadId: leadProp.id,
          },
          // data format for deleteUser and verifyUser
          leadInfo_delete: {
            lead: {
              id: leadProp.id,
              transferType: leadProp?.transferType,
              _version: leadProp?._version,
            },
            event: {
              id: leadProp?.event?.id,
              _version: leadProp?.event?._version,
            },
          },
          openerName: leadProp.opener?.name,
          closerName: leadProp.closer?.name,
          processor: leadProp.provider?.name,
          creationDate: leadProp.createdAt,
          lastUpdate: leadProp.updatedAt,
          callbackDate: leadProp?.event?.callbackDate,
          clientId: group[0].clientId,
        })),
      }));
  };
  useEffect(() => {
    if (!loaded) {
      fetchData();
      setLoaded(true);
    }
  }, [loaded]);

  const fetchData = async () => {
    const leads = await queryLeadsWithFilters(setData, {
      closed: { ne: true },
    });

    const groupedAndFiltered = groupAndFilterLeads(leads);
    setGroupedData(groupedAndFiltered);
  };

  const mainColumns = [
    {
      title: "Company Name",
      dataIndex: ["companyName"],
      key: "company",
      ellipsis: true,
    },
    {
      title: "Owner Name",
      dataIndex: ["ownerName"],
      key: "owner",
      ellipsis: true,
    },
    {
      title: "Phone 1",
      dataIndex: ["clientId"],
      key: "phone1",
      ellipsis: true,
    },
    {
      title: "Phone 2",
      dataIndex: ["phone2"],
      key: "phone2",
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: ["email"],
      key: "email",
      ellipsis: true,
    },
  ];

  const expandedRowRender = (record) => {
    const expandedColumns = [
      {
        title: "Creation Date",
        dataIndex: ["creationDate"],
        key: "creationDate",
        ellipsis: true,
        render: (a) => dayjs(a).format("DD/MM/YY hh:mm A"),
      },
      {
        title: "Opener",
        dataIndex: ["openerName"],
        key: "opener",
        ellipsis: true,
      },
      {
        title: "Last Update",
        dataIndex: "lastUpdate",
        render: (a) => dayjs(a).format("DD/MM/YY hh:mm A"),
        key: "lastUpdate",
        ellipsis: true,
      },
      {
        title: "Closer",
        dataIndex: ["closerName"],
        key: "closer",
        ellipsis: true,
      },
      {
        title: "Next callBack",
        dataIndex: ["callbackDate"],
        key: "callbackDate",
        render: (a) => (a ? dayjs(a).format("DD/MM/YY hh:mm A") : null),
        ellipsis: true,
      },
      {
        title: "Processor",
        dataIndex: ["processor"],
        key: "provider",
        ellipsis: true,
      },
      {
        title: "Choose",
        key: "actions",
        ellipsis: true,
        render: (record) => (
          <Space size="middle">
            <a
              onClick={(event) => {
                // Stop the click event from bubbling up to the row's onClick event
                event.stopPropagation();
                // Programmatically trigger the Popconfirm here
                Modal.confirm({
                  title: "Confirm to choose",
                  content: `Are you sure to select this lead, opened by ${
                    record.openerName
                  } at ${dayjs(record.creationDate).format(
                    "DD/MM/YY hh:mm A"
                  )}?`,
                  onOk: () => chooseOneFromDuped(record), 
                  okText: "Yes",
                  cancelText: "No",
                });
              }}
            >
              <CheckOutlined style={{ color: "green" }} />
            </a>
          </Space>
        ),
      },
    ];

    
    // Impementation of logic
    const chooseOneFromDuped = (record) => {
        const group = groupedData.find((group) => group.key === record.clientId);
        setVerifiedLead(record.leadInfo_delete, true, setLoaded)
        group.subs.forEach((sub, ind) => {
          if (sub.key !== record.key) {
            deleteLead(sub.leadInfo_delete, setLoaded);
          }
        });
    };

    return (
      <div className="highlighted-expanded-table">
        <Table
          columns={expandedColumns}
          dataSource={record.subs}
          pagination={false}
          onRow={(record) => ({
            onClick: () => showDrawer(record), // Open the drawer with the clicked lead's data
          })}
        />
      </div>
    );
  };

  const styleHighlighted = `
  .ant-table-tbody > tr.highlighted-row > td {
    background-color: #F5F5F5 !important; 
  }

  .highlighted-expanded-table .ant-table-thead > tr > th {
    background-color: #F8F8FF !important;
  }

  .highlighted-expanded-table .ant-table-tbody > tr > td {
    background-color: #F8F8FF !important;
  }
`;

  const getRowClassName = (record) => {
    return expandedRowKeys.includes(record.key) ? "highlighted-row" : "";
  };

  const onExpand = (expanded, record) => {
    const keys = expanded
      ? [...expandedRowKeys, record.key]
      : expandedRowKeys.filter((key) => key !== record.key);
    setExpandedRowKeys(keys);
  };

  const showDrawer = (lead) => {
    setSelectedLead(lead.leadInfo_notes);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setSelectedLead(null);
  };
  if (!loaded) {
    return <Spin size="large" />;
  } else {
    return (
      <>
        <style>{styleHighlighted}</style>

        <Table
          columns={mainColumns}
          dataSource={groupedData}
          expandable={{
            expandedRowRender: (record) => expandedRowRender(record),
            onExpand: onExpand,
          }}
          rowClassName={(record, index) => getRowClassName(record)}
        />
        <Drawer
          title="Lead Details"
          placement="right"
          closable={true}
          onClose={closeDrawer}
          open={drawerVisible}
          width={400}
        >
          {selectedLead && (
            <div>
              <Layout>
                <Notes leadInfo={selectedLead} />
              </Layout>
            </div>
          )}
        </Drawer>
      </>
    );
  }
};

export default ListLeadsDuped;