import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  Layout,
  Modal,
  Radio,
} from "antd";
import {
  updateLeadStatus,
  mutationCreateNote,
  getProviders,
  saveLead,
  addCallback,
  updateTransferType,
  getClosers,
} from "./utils/dataHooks";
import { Descriptions, message } from "antd";
import { Content } from "antd/es/layout/layout";
import dayjs from "dayjs";

const { Option } = Select;
const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 4,
    span: 20,
  },
};
const FormLead_closer = (props) => {
  // console.log("props FormLead_closer", props)

  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [disableButtons, setDisableButtons] = useState(
    props.leadInfo ? false : true
  );
  const [providers, setProviders] = useState(null);

  useEffect(() => {
    const fetchProviders = async () => {
      const providers = await getProviders();
      const closers = await getClosers();
      var providersList = [];
      var closersList = [];
      var other;
      var noProcessor;
      providers.forEach((provider) => {
        if (provider.name === "Other") {
          other = {
            label: provider.name,
            value: provider.id,
          };
        } else if (provider.name === "No processor") {
          noProcessor = {
            label: provider.name,
            value: provider.id,
          };
        } else {
          providersList.push({
            label: provider.name,
            value: provider.id,
          });
        }
      });
      closers.forEach((closer) => {
        closersList.push({
          label: closer.name,
          value: closer.id,
        });
      });
      providersList.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
      providersList.push(other);
      providersList.unshift(noProcessor);
      setProviders(providersList);
    };
    fetchProviders();
  }, []);

  useEffect(() => {
    if (props?.leadInfo) {
      form.setFieldsValue({
        entreprise: props.leadInfo ? props.leadInfo?.lead?.client?.name : null,
        nom: props.leadInfo ? props.leadInfo?.lead?.client?.referentName : null,
        tel: props.leadInfo ? props?.leadInfo?.lead?.client?.id : null,
        tel2: props.leadInfo ? props?.leadInfo?.lead?.client?.phone : null,
        email: props.leadInfo ? props.leadInfo?.lead?.client?.email : null,
        score: props.leadInfo ? props.leadInfo?.lead?.score : null,
        fournisseur: props.leadInfo?.lead?.provider
          ? props?.leadInfo?.lead?.provider?.id
          : null,
        language: props.leadInfo ? props.leadInfo?.lead?.client?.language : null,
        pos: props.leadInfo ? props.leadInfo?.lead?.pos : null,
      });
    }
  }, [props]);

  const inputwidth = 350;

  const onFinish = (values) => {
    //console.log(form.getFieldsValue());
  };

  const showCallBackModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSave = async () => {
    if (props.leadInfo) {
      await saveLead(
        props.leadInfo,
        form.getFieldValue(),
        props.setContainedData,
        props.setLoaded
      );
    } 
  };
  const onCallBack = async () => {
    // console.log("onCallBAck, props", props);
  
    if (props.leadInfo) {
      try {
        // Save the lead
        await saveLead(
          props.leadInfo,
          form.getFieldValue(),
          props.setContainedData,
        );
  
        // Add callback
        await addCallback(
          props.leadInfo,
          form.getFieldValue().callbackDate,
          // props.setLoaded
        );
          // console.log("props.leadInfo?.lead?.status", props.leadInfo?.lead?.status)
        // Update lead status if necessary
        if (props.leadInfo?.lead?.status === "Assigned") {
          await updateLeadStatus(
            props.leadInfo,
            "In Progress"
          );
        }
      } catch (error) {
        console.error('Error in onCallBack:', error);
        // Handle any errors that might occur during the async operations
      }
    }
  
    // Close the drawer and reset loaded state
    if (props?.setIsDrawerOpen) {
      props.setIsDrawerOpen(false);
    }
    if (props?.setLoaded) {
      props.setLoaded(false);
    }
  };
  
  // const onCallBack = () => {
  //   console.log("onCallBAck, props.leadInfo", props.leadInfo)
  //   if (props.leadInfo) {
  //     //save lead
  //     saveLead(
  //       props.leadInfo,
  //       form.getFieldValue(),
  //       props.setContainedData,
  //     );

  //     addCallback(
  //       props.leadInfo,
  //       form.getFieldValue().callbackDate,
  //       props.setLoaded
  //     );
  //     if (props.leadInfo?.lead?.status === "Assigned"){
  //       updateLeadStatus(
  //         props.leadInfo,
  //         "In Progress"
  //       )
  //     }
      
  //   }
  //   if(props?.setIsDrawerOpen){
  //     props.setIsDrawerOpen(false);
  //   }
  //   if(props?.setLoaded){
  //     props.setLoaded(false);
  //   }
  // };


  const readonlyitems = [
    {
      key: "1",
      label: "Lead Creator",
      children: 
      props?.leadInfo?.lead?.opener?.name
    },
    {
      key: "2",
      label: "Next Callback",
      children:

        props?.leadInfo?.lead?.event?.callbackDate &&
        dayjs(props?.leadInfo?.lead?.event?.callbackDate).format("YYYY-MM-DD HH:mm"),

    },
  ];

  /* This is for the Calendar selection
   */
  const onChange = (value, dateString) => {
    form.setFieldValue("callbackDate", value);
  };
  const onOk = (value) => {
    form.setFieldValue("callbackDate", value);
  };

  const onValuesChange = (changedValues, values) => {
    if (
      values.nom != undefined &&
      values.nom !== "" &&
      values.entreprise != undefined &&
      values.entreprise !== "" &&
      values.tel != undefined &&
      values.tel !== ""
    ) {
      setDisableButtons(false);
    } else {
      setDisableButtons(true);
    }
  };

  const actions = [
    {
      label: "Not interested",
      value: "Not interested",
      message: "Marked as Not Interested",
    },
    {
      label: "In review",
      value: "In Review",
      message: "Send to review",
    },
  ];
  const onButtonLeadStatusClick = async (value, message) => {
    await saveLead(
      props.leadInfo,
      form.getFieldValue(),
      props.setContainedData,
    );
    await updateLeadStatus(props.leadInfo, value);
    messageApi.info(message);
    if(props?.onClose){
      props.onClose();
    }
    if(props?.setIsDrawerOpen){
      props.setIsDrawerOpen(false);
    }
    if(value ==="Not interested"){
      if (props?.parentData){
        const filtered_data = props.parentData.filter((item)=>{return item.id !== props.leadInfo.id})
        props.setParentData(filtered_data)
        props.setParentContainedData({})
      }else{
        if(props?.setLoaded){
          props.setLoaded(false);
        }
      }
    } else{
      props.setLoaded(false);
    }
  };

  if (!providers) <>Loading...</>;
  else {
    return (
      <Form
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Layout style={{ background: "white" }}>
          {contextHolder}
          <Content>
            <Form.Item>
            {readonlyitems.map((item) => (
        <Descriptions key={item.key} items={[item]} />
      ))}
            </Form.Item>
            <Form.Item
              name="entreprise"
              label="Company name"
              initialValue={
                props.leadInfo ? props?.leadInfo?.lead?.client?.name : null
              }
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
            <Form.Item
              name="nom"
              label="Owner Name"
              initialValue={
                props.leadInfo
                  ? props.leadInfo?.lead?.client?.referentName
                  : null
              }
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
            <Form.Item
              name="tel"
              label="Phone Number"
              initialValue={
                props.leadInfo ? props?.leadInfo?.lead?.client?.id : null
              }
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} disabled={true} />
            </Form.Item>
            <Form.Item
        name="score"
        label="Score"
      >
     <Radio.Group>
          <Space direction="horizontal" size="large">
            <Radio.Button value="Hot" style={{ display: 'flex', alignItems: 'center', padding: '0 12px' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: 'green', marginRight: 8 }} />
                Hot
              </span>
            </Radio.Button>
            <Radio.Button value="Warm" style={{ display: 'flex', alignItems: 'center', padding: '0 12px' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: 'orange', marginRight: 8 }} />
                Warm
              </span>
            </Radio.Button>
            <Radio.Button value="Cold" style={{ display: 'flex', alignItems: 'center', padding: '0 12px' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: 'red', marginRight: 8 }} />
                Cold
              </span>
            </Radio.Button>
          </Space>
        </Radio.Group>
      </Form.Item>
            <Form.Item
              name="tel2"
              label="Phone Number 2"
              initialValue={
                props.leadInfo ? props?.leadInfo?.lead?.client?.phone : null
              }
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              initialValue={
                props.leadInfo ? props.leadInfo?.lead?.client?.email : null
              }
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
            <Form.Item
              name="fournisseur"
              label="Processor"
              initialValue={
                props.leadInfo?.lead?.provider
                  ? props?.leadInfo?.lead?.provider?.id
                  : null
              }
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                placeholder="Select an option and change input text above"
                style={{ width: inputwidth }}
                allowClear
                options={providers}
              ></Select>
            </Form.Item>
            <Form.Item
              name="language"
              label="Language"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                placeholder="Select an option and change input text above"
                style={{ width: inputwidth }}
                allowClear
                options={["French", "English", "Other"].map((item)=>{return {label:item, value:item}})}
              ></Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.gender !== currentValues.gender
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("gender") === "other" ? (
                  <Form.Item
                    name="customizeGender"
                    label="Customize Gender"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input style={{ width: inputwidth }} />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
            <Form.Item
              name="pos"
              label="POS"
              initialValue={props.leadInfo ? props.leadInfo?.lead?.pos : null}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
            {!props?.leadInfo && (
              <Form.Item name="notes" label="Notes">
                <TextArea rows={4} style={{ width: inputwidth }} />
              </Form.Item>
            )}
          </Content>

          {/* buttons section */}
          <Form.Item {...tailLayout}>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
              {props?.leadInfo && (
                <Button
                  type="primary"
                  onClick={onSave}
                  disabled={disableButtons}
                >
                  Save
                </Button>
              )}
              { <Button
                  type="primary"
                  onClick={showCallBackModal}
                  disabled={disableButtons}
                >
                  Set Callback
                </Button>

              }
              <Modal
                title="Set Callback"
                open={isModalOpen}
                onOk={onCallBack}
                onCancel={handleCancel}
              >
                <Form.Item name="callbackDate" label="Callback Date">
                  <Space direction="vertical" size={12}>
                    <DatePicker
                      showTime
                      onChange={onChange}
                      onOk={onOk}
                      minuteStep={5}
                      format={"DD/MM/YY hh:mm A"}
                    />
                  </Space>
                </Form.Item>
              </Modal>

              {actions.map((action) => (
                <Button
                  key={action.label}
                  type="primary"
                  onClick={() =>
                    onButtonLeadStatusClick(action.value, action.message)
                  }
                >
                  {action.label}
                </Button>
              ))}
            </div>
          </Form.Item>
        </Layout>
      </Form>
    );
  }
};
export default FormLead_closer;
