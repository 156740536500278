import { useState, useEffect } from "react";
import InProgressTable from "./tableCloserInProgress";
import MyLeadsTable from "./tableCloserMyLeads";
import { queryLeadsByUserAsCloser, delay } from "./utils/dataHooks";

const ListLeadsCloser = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [containeddata, setContainedData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [wait, setWait] = useState(false)
  const fetchData = async () => {
    // setUser(await Auth.currentAuthenticatedUser());
    return await queryLeadsByUserAsCloser(setData).then(() => {
      setLoaded(true);
      setWait(true)
    });
  };

  useEffect(() => {
    if (!loaded){
      if(wait){
        const wait_to_ensure_db_is_updated = async () => {
          await delay(1000);
        }
        wait_to_ensure_db_is_updated();
      }
      fetchData();
    }
    
  }, [loaded]);

  if (!loaded) {
    return <>Loading</>;
  }

  return (
    <>
      <MyLeadsTable
        isOpenDrawer={isOpenDrawer}
        setIsOpenDrawer={setIsOpenDrawer}
        containeddata={containeddata}
        setContainedData={setContainedData}
        loaded={loaded}
        setLoaded={setLoaded}
        data={data}
        setData={setData}
        fetchData={fetchData}
      ></MyLeadsTable>
      <InProgressTable
        isOpenDrawer={isOpenDrawer}
        setIsOpenDrawer={setIsOpenDrawer}
        containeddata={containeddata}
        setContainedData={setContainedData}
        loaded={loaded}
        setLoaded={setLoaded}
        data={data}
        setData={setData}
        fetchData={fetchData}
      ></InProgressTable>
    </>
  );
};
export default ListLeadsCloser;
