/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const UserManagement = /* GraphQL */ `
  mutation UserManagement($event: UserInput) {
    UserManagement(event: $event) {
      id
      name
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      role
      status
      leads {
        items {
          id
          clientId
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          openerId
          opener {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          closerId
          closer {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          providerId
          provider {
            id
            name
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          pos
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tags
          transferType
          transferDate
          status
          score
          statusChangeDate
          isVerified
          eventId
          event {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          leadsCount
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      leadsToClose {
        items {
          id
          clientId
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          openerId
          opener {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          closerId
          closer {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          providerId
          provider {
            id
            name
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          pos
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tags
          transferType
          transferDate
          status
          score
          statusChangeDate
          isVerified
          eventId
          event {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          leadsCount
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      events {
        items {
          id
          userId
          user {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          leadId
          lead {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          type
          eventDate
          callbackDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      notes {
        items {
          id
          owner
          user {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          note
          createdAt
          updatedAt
          leadId
          lead {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      role
      status
      leads {
        items {
          id
          clientId
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          openerId
          opener {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          closerId
          closer {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          providerId
          provider {
            id
            name
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          pos
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tags
          transferType
          transferDate
          status
          score
          statusChangeDate
          isVerified
          eventId
          event {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          leadsCount
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      leadsToClose {
        items {
          id
          clientId
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          openerId
          opener {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          closerId
          closer {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          providerId
          provider {
            id
            name
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          pos
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tags
          transferType
          transferDate
          status
          score
          statusChangeDate
          isVerified
          eventId
          event {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          leadsCount
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      events {
        items {
          id
          userId
          user {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          leadId
          lead {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          type
          eventDate
          callbackDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      notes {
        items {
          id
          owner
          user {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          note
          createdAt
          updatedAt
          leadId
          lead {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      role
      status
      leads {
        items {
          id
          clientId
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          openerId
          opener {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          closerId
          closer {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          providerId
          provider {
            id
            name
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          pos
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tags
          transferType
          transferDate
          status
          score
          statusChangeDate
          isVerified
          eventId
          event {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          leadsCount
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      leadsToClose {
        items {
          id
          clientId
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          openerId
          opener {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          closerId
          closer {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          providerId
          provider {
            id
            name
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          pos
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tags
          transferType
          transferDate
          status
          score
          statusChangeDate
          isVerified
          eventId
          event {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          leadsCount
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      events {
        items {
          id
          userId
          user {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          leadId
          lead {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          type
          eventDate
          callbackDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      notes {
        items {
          id
          owner
          user {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          note
          createdAt
          updatedAt
          leadId
          lead {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      name
      isCompany
      referentName
      phone
      email
      language
      address
      leads {
        items {
          id
          clientId
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          openerId
          opener {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          closerId
          closer {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          providerId
          provider {
            id
            name
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          pos
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tags
          transferType
          transferDate
          status
          score
          statusChangeDate
          isVerified
          eventId
          event {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          leadsCount
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      name
      isCompany
      referentName
      phone
      email
      language
      address
      leads {
        items {
          id
          clientId
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          openerId
          opener {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          closerId
          closer {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          providerId
          provider {
            id
            name
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          pos
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tags
          transferType
          transferDate
          status
          score
          statusChangeDate
          isVerified
          eventId
          event {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          leadsCount
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      name
      isCompany
      referentName
      phone
      email
      language
      address
      leads {
        items {
          id
          clientId
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          openerId
          opener {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          closerId
          closer {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          providerId
          provider {
            id
            name
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          pos
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tags
          transferType
          transferDate
          status
          score
          statusChangeDate
          isVerified
          eventId
          event {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          leadsCount
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createProvider = /* GraphQL */ `
  mutation CreateProvider(
    $input: CreateProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    createProvider(input: $input, condition: $condition) {
      id
      name
      leads {
        items {
          id
          clientId
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          openerId
          opener {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          closerId
          closer {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          providerId
          provider {
            id
            name
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          pos
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tags
          transferType
          transferDate
          status
          score
          statusChangeDate
          isVerified
          eventId
          event {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          leadsCount
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateProvider = /* GraphQL */ `
  mutation UpdateProvider(
    $input: UpdateProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    updateProvider(input: $input, condition: $condition) {
      id
      name
      leads {
        items {
          id
          clientId
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          openerId
          opener {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          closerId
          closer {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          providerId
          provider {
            id
            name
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          pos
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tags
          transferType
          transferDate
          status
          score
          statusChangeDate
          isVerified
          eventId
          event {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          leadsCount
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteProvider = /* GraphQL */ `
  mutation DeleteProvider(
    $input: DeleteProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    deleteProvider(input: $input, condition: $condition) {
      id
      name
      leads {
        items {
          id
          clientId
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          openerId
          opener {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          closerId
          closer {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          providerId
          provider {
            id
            name
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          pos
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tags
          transferType
          transferDate
          status
          score
          statusChangeDate
          isVerified
          eventId
          event {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          leadsCount
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLead = /* GraphQL */ `
  mutation CreateLead(
    $input: CreateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    createLead(input: $input, condition: $condition) {
      id
      clientId
      client {
        id
        name
        isCompany
        referentName
        phone
        email
        language
        address
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      openerId
      opener {
        id
        name
        role
        status
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        leadsToClose {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        events {
          items {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      closerId
      closer {
        id
        name
        role
        status
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        leadsToClose {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        events {
          items {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      providerId
      provider {
        id
        name
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      pos
      createdAt
      updatedAt
      closed
      deleted
      notes {
        items {
          id
          owner
          user {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          note
          createdAt
          updatedAt
          leadId
          lead {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tags
      transferType
      transferDate
      status
      score
      statusChangeDate
      isVerified
      eventId
      event {
        id
        userId
        user {
          id
          name
          role
          status
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          leadsToClose {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          events {
            items {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        leadId
        lead {
          id
          clientId
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          openerId
          opener {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          closerId
          closer {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          providerId
          provider {
            id
            name
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          pos
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tags
          transferType
          transferDate
          status
          score
          statusChangeDate
          isVerified
          eventId
          event {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          leadsCount
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        type
        eventDate
        callbackDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      leadsCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLead = /* GraphQL */ `
  mutation UpdateLead(
    $input: UpdateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    updateLead(input: $input, condition: $condition) {
      id
      clientId
      client {
        id
        name
        isCompany
        referentName
        phone
        email
        language
        address
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      openerId
      opener {
        id
        name
        role
        status
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        leadsToClose {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        events {
          items {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      closerId
      closer {
        id
        name
        role
        status
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        leadsToClose {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        events {
          items {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      providerId
      provider {
        id
        name
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      pos
      createdAt
      updatedAt
      closed
      deleted
      notes {
        items {
          id
          owner
          user {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          note
          createdAt
          updatedAt
          leadId
          lead {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tags
      transferType
      transferDate
      status
      score
      statusChangeDate
      isVerified
      eventId
      event {
        id
        userId
        user {
          id
          name
          role
          status
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          leadsToClose {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          events {
            items {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        leadId
        lead {
          id
          clientId
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          openerId
          opener {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          closerId
          closer {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          providerId
          provider {
            id
            name
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          pos
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tags
          transferType
          transferDate
          status
          score
          statusChangeDate
          isVerified
          eventId
          event {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          leadsCount
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        type
        eventDate
        callbackDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      leadsCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLead = /* GraphQL */ `
  mutation DeleteLead(
    $input: DeleteLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    deleteLead(input: $input, condition: $condition) {
      id
      clientId
      client {
        id
        name
        isCompany
        referentName
        phone
        email
        language
        address
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      openerId
      opener {
        id
        name
        role
        status
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        leadsToClose {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        events {
          items {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      closerId
      closer {
        id
        name
        role
        status
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        leadsToClose {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        events {
          items {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      providerId
      provider {
        id
        name
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      pos
      createdAt
      updatedAt
      closed
      deleted
      notes {
        items {
          id
          owner
          user {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          note
          createdAt
          updatedAt
          leadId
          lead {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tags
      transferType
      transferDate
      status
      score
      statusChangeDate
      isVerified
      eventId
      event {
        id
        userId
        user {
          id
          name
          role
          status
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          leadsToClose {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          events {
            items {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        leadId
        lead {
          id
          clientId
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          openerId
          opener {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          closerId
          closer {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          providerId
          provider {
            id
            name
            leads {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          pos
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tags
          transferType
          transferDate
          status
          score
          statusChangeDate
          isVerified
          eventId
          event {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          leadsCount
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        type
        eventDate
        callbackDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      leadsCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEventCalendar = /* GraphQL */ `
  mutation CreateEventCalendar(
    $input: CreateEventCalendarInput!
    $condition: ModelEventCalendarConditionInput
  ) {
    createEventCalendar(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        name
        role
        status
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        leadsToClose {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        events {
          items {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      leadId
      lead {
        id
        clientId
        client {
          id
          name
          isCompany
          referentName
          phone
          email
          language
          address
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        openerId
        opener {
          id
          name
          role
          status
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          leadsToClose {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          events {
            items {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        closerId
        closer {
          id
          name
          role
          status
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          leadsToClose {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          events {
            items {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        provider {
          id
          name
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        pos
        createdAt
        updatedAt
        closed
        deleted
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tags
        transferType
        transferDate
        status
        score
        statusChangeDate
        isVerified
        eventId
        event {
          id
          userId
          user {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          leadId
          lead {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          type
          eventDate
          callbackDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        leadsCount
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      type
      eventDate
      callbackDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateEventCalendar = /* GraphQL */ `
  mutation UpdateEventCalendar(
    $input: UpdateEventCalendarInput!
    $condition: ModelEventCalendarConditionInput
  ) {
    updateEventCalendar(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        name
        role
        status
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        leadsToClose {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        events {
          items {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      leadId
      lead {
        id
        clientId
        client {
          id
          name
          isCompany
          referentName
          phone
          email
          language
          address
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        openerId
        opener {
          id
          name
          role
          status
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          leadsToClose {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          events {
            items {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        closerId
        closer {
          id
          name
          role
          status
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          leadsToClose {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          events {
            items {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        provider {
          id
          name
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        pos
        createdAt
        updatedAt
        closed
        deleted
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tags
        transferType
        transferDate
        status
        score
        statusChangeDate
        isVerified
        eventId
        event {
          id
          userId
          user {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          leadId
          lead {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          type
          eventDate
          callbackDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        leadsCount
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      type
      eventDate
      callbackDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteEventCalendar = /* GraphQL */ `
  mutation DeleteEventCalendar(
    $input: DeleteEventCalendarInput!
    $condition: ModelEventCalendarConditionInput
  ) {
    deleteEventCalendar(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        name
        role
        status
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        leadsToClose {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        events {
          items {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      leadId
      lead {
        id
        clientId
        client {
          id
          name
          isCompany
          referentName
          phone
          email
          language
          address
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        openerId
        opener {
          id
          name
          role
          status
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          leadsToClose {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          events {
            items {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        closerId
        closer {
          id
          name
          role
          status
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          leadsToClose {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          events {
            items {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        provider {
          id
          name
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        pos
        createdAt
        updatedAt
        closed
        deleted
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tags
        transferType
        transferDate
        status
        score
        statusChangeDate
        isVerified
        eventId
        event {
          id
          userId
          user {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          leadId
          lead {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          type
          eventDate
          callbackDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        leadsCount
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      type
      eventDate
      callbackDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      owner
      user {
        id
        name
        role
        status
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        leadsToClose {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        events {
          items {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      note
      createdAt
      updatedAt
      leadId
      lead {
        id
        clientId
        client {
          id
          name
          isCompany
          referentName
          phone
          email
          language
          address
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        openerId
        opener {
          id
          name
          role
          status
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          leadsToClose {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          events {
            items {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        closerId
        closer {
          id
          name
          role
          status
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          leadsToClose {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          events {
            items {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        provider {
          id
          name
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        pos
        createdAt
        updatedAt
        closed
        deleted
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tags
        transferType
        transferDate
        status
        score
        statusChangeDate
        isVerified
        eventId
        event {
          id
          userId
          user {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          leadId
          lead {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          type
          eventDate
          callbackDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        leadsCount
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      owner
      user {
        id
        name
        role
        status
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        leadsToClose {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        events {
          items {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      note
      createdAt
      updatedAt
      leadId
      lead {
        id
        clientId
        client {
          id
          name
          isCompany
          referentName
          phone
          email
          language
          address
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        openerId
        opener {
          id
          name
          role
          status
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          leadsToClose {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          events {
            items {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        closerId
        closer {
          id
          name
          role
          status
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          leadsToClose {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          events {
            items {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        provider {
          id
          name
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        pos
        createdAt
        updatedAt
        closed
        deleted
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tags
        transferType
        transferDate
        status
        score
        statusChangeDate
        isVerified
        eventId
        event {
          id
          userId
          user {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          leadId
          lead {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          type
          eventDate
          callbackDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        leadsCount
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      owner
      user {
        id
        name
        role
        status
        leads {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        leadsToClose {
          items {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        events {
          items {
            id
            userId
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      note
      createdAt
      updatedAt
      leadId
      lead {
        id
        clientId
        client {
          id
          name
          isCompany
          referentName
          phone
          email
          language
          address
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        openerId
        opener {
          id
          name
          role
          status
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          leadsToClose {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          events {
            items {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        closerId
        closer {
          id
          name
          role
          status
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          leadsToClose {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          events {
            items {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        provider {
          id
          name
          leads {
            items {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        pos
        createdAt
        updatedAt
        closed
        deleted
        notes {
          items {
            id
            owner
            user {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            note
            createdAt
            updatedAt
            leadId
            lead {
              id
              clientId
              openerId
              closerId
              providerId
              pos
              createdAt
              updatedAt
              closed
              deleted
              tags
              transferType
              transferDate
              status
              score
              statusChangeDate
              isVerified
              eventId
              leadsCount
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tags
        transferType
        transferDate
        status
        score
        statusChangeDate
        isVerified
        eventId
        event {
          id
          userId
          user {
            id
            name
            role
            status
            leads {
              nextToken
              startedAt
              __typename
            }
            leadsToClose {
              nextToken
              startedAt
              __typename
            }
            events {
              nextToken
              startedAt
              __typename
            }
            notes {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          leadId
          lead {
            id
            clientId
            client {
              id
              name
              isCompany
              referentName
              phone
              email
              language
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            openerId
            opener {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            closerId
            closer {
              id
              name
              role
              status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            providerId
            provider {
              id
              name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            pos
            createdAt
            updatedAt
            closed
            deleted
            notes {
              nextToken
              startedAt
              __typename
            }
            tags
            transferType
            transferDate
            status
            score
            statusChangeDate
            isVerified
            eventId
            event {
              id
              userId
              leadId
              type
              eventDate
              callbackDate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            leadsCount
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          type
          eventDate
          callbackDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        leadsCount
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
