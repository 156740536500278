import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  DatePicker,
  Layout,
  Modal,
  Spin,
} from "antd";
import {
  handleCreateLead,
  handleDuplicateRequest,
  // mutationCreateLead,
  mutationCreateNote,
  createCallback,
  getProviders,
  queryClient,
  saveLead,
  deleteLead,
  addCallback,
  updateTransferType,
  getClosers,
} from "./utils/dataHooks";
import { Descriptions, message } from "antd";
import { Content } from "antd/es/layout/layout";
import { Auth } from "aws-amplify";
import dayjs from "dayjs";


const { Option } = Select;
const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 4,
    span: 20,
  },
};
const FormLead = (props) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCloserModalOpen, setIsCloserModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [dupeFlag, setDupeFlag] = useState(false);
  const [disableButtons, setDisableButtons] = useState(
    props.leadInfo ? false : true
  );
  const [providers, setProviders] = useState(null);
  const [closers, setClosers] = useState(null);
  const [user, setUser] = useState(null);
// it is for preventing unsaving data
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchProviders = async () => {
      const providers = await getProviders();
      const closers = await getClosers();
      setUser(await Auth.currentAuthenticatedUser());
      var providersList = [];
      var closersList = [];
      var other;
      var noProcessor;
      providers.forEach((provider) => {
        if (provider.name === "Other") {
          other = {
            label: provider.name,
            value: provider.id,
          };
        } else if (provider.name === "No processor") {
          noProcessor = {
            label: provider.name,
            value: provider.id,
          };
        } else {
          providersList.push({
            label: provider.name,
            value: provider.id,
          });
        }
      });
      closers.forEach((closer) => {
        closersList.push({
          label: closer.name,
          value: closer.id,
        });
      });
      providersList.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
      providersList.push(other);
      providersList.unshift(noProcessor);
      setProviders(providersList);
      setClosers(closersList);
    };
    fetchProviders();
  }, []);

  useEffect(() => {
    if (props?.leadInfo){
      form.setFieldsValue({
        entreprise: props.leadInfo ? props.leadInfo.lead.client.name : null,
        nom: props.leadInfo ? props.leadInfo?.lead?.client?.referentName : null,
        tel: props.leadInfo ? props?.leadInfo?.lead?.client?.id : null,
        tel2: props.leadInfo ? props?.leadInfo?.lead?.client?.phone : null,
        email: props.leadInfo ? props.leadInfo?.lead?.client?.email : null,
        fournisseur: props.leadInfo?.lead?.provider
          ? props?.leadInfo?.lead?.provider?.id
          : null,
        pos: props.leadInfo ? props.leadInfo?.lead?.pos : null,
      });
    }
  }, [props]);

  const inputwidth = 350;

  const onFinish = (values) => {
    //console.log(form.getFieldsValue());
  };
  const onReset = () => {
    form.resetFields();
  };

  const showCallBackModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showCloserModal = () => {
    setIsCloserModalOpen(true);
  };

  const handleLiveCancel = () => {
    if (!props.leadInfo) {
      form.setFieldValue("closer", null);
    }
    setIsCloserModalOpen(false);
  };

  const onSave = () => {
    setIsLoading(true)
    messageApi.info("Saving... If you go somewhere else, changes might not be saved.");
    if (props.leadInfo) {

      saveLead(
        props.leadInfo,
        form.getFieldValue(),
        props.setContainedData,
        props.setLoaded
      );
    } else {
      handleCreateLead(form.getFieldsValue(), false).then((lead) =>
        addNote(lead, form.getFieldValue().notes)
      );
    }
  };

  const onCallBack = () => {
    setIsLoading(true)
    messageApi.info("Saving... If you go somewhere else, changes might not be saved.");
    if (props.leadInfo) {
      addCallback(
        props.leadInfo,
        form.getFieldValue().callbackDate,
        props.setLoaded
      );
      props.leadInfo.callbackDate = form.getFieldValue().callbackDate
    } else {
      handleCreateLead(form.getFieldsValue(), true).then((lead) =>
        addNote(lead, form.getFieldValue().notes)
      );
      setIsModalOpen(false);
    }
  };

  const onWarmTransfer = () => {
    if (props.leadInfo) {
      updateTransferType(props.leadInfo, "Warm", null, props.setLoaded);
      messageApi.info("Set to Warm transfer");
      props.setOpen();
    } else {
      handleCreateLead(form.getFieldsValue(), false, "Warm").then((lead) =>
        addNote(lead, form.getFieldValue().notes)
      );
    }
  };

  const onLiveTransfer = () => {
    if (props.leadInfo) {
      updateTransferType(
        props.leadInfo,
        "Live",
        form.getFieldValue("closer"),
        props.setLoaded
      );
      messageApi.info(
        "Transferred to " +
          closers.filter(
            (closer) => closer.value === form.getFieldValue("closer")
          )[0].label
      );
      setIsCloserModalOpen(false);
      props.setOpen();
    } else {
      handleCreateLead(form.getFieldsValue(), false, "Live").then((lead) =>
        addNote(lead, form.getFieldValue().notes)
      );
      setIsCloserModalOpen(false);
    }
  };

  const checkPhone = () => {
    if (form.getFieldValue().tel !== null && form.getFieldValue().tel !== "") {
      queryClient(form.getFieldValue().tel, setDupeFlag);
    }
  };

  const onRequestDuped = () => {
    if (props.leadInfo) {
      updateTransferType(props.leadInfo, "Request Dupe");
    } else {
      handleDuplicateRequest(form.getFieldsValue()).then(
           (leadInfo) => addNote(leadInfo, form.getFieldValue().notes))
      // mutationCreateLead(form.getFieldsValue(), false, "Request Dupe").then(
      //   (leadInfo) => addNote(leadInfo, form.getFieldValue().notes)
      // );
    }
  };

  const addNote = (lead, notes) => {
    if (notes) {
      mutationCreateNote(lead.id, notes);
    }
    form.resetFields();
    if (lead?.errors) {
      message.error("Something went wrong");
    } else {
      messageApi.info("Success");
      setIsLoading(false)
    }
  };


  // if the props is user ('Form' page) it shows his name, is it is a lead (in other places) - opener's name
  const readonlyitems = [
    {
      key: "1",
      label: "Lead Creator",
      children:
        props?.leadInfo?.lead?.opener?.name ?? props.user?.attributes?.name,
    },
    {
      key: "2",
      label: "Next Callback",
      children:
        props?.leadInfo?.callbackDate && dayjs(props?.leadInfo?.callbackDate).format("YYYY-MM-DD HH:mm"),
    }
  ];


  /* This is for the Calendar selection
   */
  const onChange = (value, dateString) => {
    form.setFieldValue("callbackDate", value);
  };
  const onOk = (value) => {
    // console.log("onOk: ", value);
    form.setFieldValue("callbackDate", value);
  };

  const onDelete = () => {
    if (props.leadInfo) {
      deleteLead(props?.leadInfo, props?.setLoaded);
    }
    form.resetFields();
    messageApi.info("Dropped Lead");
    props.setOpen();
  };

  //Disable buttons if nom, entreprise and tel are not filled
  const onValuesChange = (changedValues, values) => {
    if (
      values.nom != undefined &&
      values.nom !== "" &&
      values.entreprise != undefined &&
      values.entreprise !== "" &&
      values.tel != undefined &&
      values.tel !== ""
    ) {
      setDisableButtons(false);
    } else {
      setDisableButtons(true);
    }
  };
  if (!providers) return <Spin size="large" />;
  else {
    return (
      <Form
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Layout style={{ background: "white" }}>
          {contextHolder}
          <Content>
            <Form.Item>
              <Descriptions title="Lead" items={readonlyitems} />
            </Form.Item>
            <Form.Item
              name="entreprise"
              label="Company name"
              initialValue={
                props.leadInfo ? props?.leadInfo?.lead?.client?.name : null
              }
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
            <Form.Item
              name="nom"
              label="Owner Name"
              initialValue={
                props.leadInfo
                  ? props.leadInfo?.lead?.client?.referentName
                  : null
              }
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
            <Form.Item
              name="tel"
              label="Phone Number"
              initialValue={
                props.leadInfo ? props?.leadInfo?.lead?.client?.id : null
              }
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ width: inputwidth }}
                onChange={checkPhone}
                disabled={props?.leadInfo ? true : false}
              />
            </Form.Item>
            <Form.Item
              name="tel2"
              label="Phone Number 2"
              initialValue={
                props.leadInfo ? props?.leadInfo?.lead?.client?.phone : null
              }
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              initialValue={
                props.leadInfo ? props.leadInfo?.lead?.client?.email : null
              }
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
            <Form.Item
              name="fournisseur"
              label="Processor"
              initialValue={
                props.leadInfo?.lead?.provider
                  ? props?.leadInfo?.lead?.provider?.id
                  : null
              }
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                placeholder="Select a option and change input text above"
                style={{ width: inputwidth }}
                allowClear
                options={providers}
              ></Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.gender !== currentValues.gender
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("gender") === "other" ? (
                  <Form.Item
                    name="customizeGender"
                    label="Customize Gender"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input style={{ width: inputwidth }} />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
            <Form.Item
              name="pos"
              label="POS"
              initialValue={props.leadInfo ? props.leadInfo?.lead?.pos : null}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
            {!props?.leadInfo && (
              <Form.Item name="notes" label="Notes">
                <TextArea rows={4} style={{ width: inputwidth }} />
              </Form.Item>
            )}
          </Content>
          <Form.Item {...tailLayout}>
            {props?.leadInfo && !dupeFlag && (
              <Button
                type="primary"
                htmlType="submit"
                onClick={onSave}
                disabled={disableButtons||isLoading}
              >
                Save
              </Button>
            )}
            {!dupeFlag && (
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "0 8px" }}
                onClick={showCallBackModal}
                disabled={disableButtons||isLoading}
              >
                Set Callback
              </Button>
            )}
            <Modal
              title="Set Callback"
              open={isModalOpen}
              onOk={onCallBack}
              onCancel={handleCancel}
            >
              <Form.Item name="callbackDate" label="Callback Date">
                <Space direction="vertical" size={12}>
                  <DatePicker
                    showTime
                    onChange={onChange}
                    onOk={onOk}
                    minuteStep={5}
                    format={"DD/MM/YY hh:mm A"}
                  />
                </Space>
              </Form.Item>
            </Modal>
            {!dupeFlag && (
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "0 8px" }}
                onClick={onWarmTransfer}
                disabled={disableButtons||isLoading}
              >
                Warm Transfer
              </Button>
            )}
            {props?.leadInfo && !dupeFlag && <p></p>}
            {!dupeFlag && (
              <Button
                type="primary"
                htmlType="submit"
                onClick={showCloserModal}
                disabled={disableButtons||isLoading}
              >
                Live Transfer
              </Button>
            )}
            <Modal
              title="Assign to a closer"
              open={isCloserModalOpen}
              onOk={onLiveTransfer}
              onCancel={handleLiveCancel}
            >
              <Form.Item
                name="closer"
                initialValue={
                  props.leadInfo?.lead?.closer?.name
                    ? props?.leadInfo?.lead?.closer?.id
                    : null
                }
              >
                <Select
                  placeholder="Select a option and change input text above"
                  style={{ width: inputwidth }}
                  allowClear
                  options={closers}
                ></Select>
              </Form.Item>
            </Modal>
            {!props?.leadInfo && dupeFlag && (
              <Popconfirm
                placement="top"
                title={"Request duplicate"}
                description={"Are you sure to request a duplicate lead ?"}
                onConfirm={onRequestDuped}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ margin: "0 8px" }}
                  disabled={disableButtons||isLoading}
                >
                  Request Duped Lead
                </Button>
              </Popconfirm>
            )}
            {props?.leadInfo && (
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "0 8px", background: "red" }}
                onClick={onDelete}
                disabled={disableButtons||isLoading}
              >
                Drop Lead
              </Button>
            )}
          </Form.Item>
        </Layout>
      </Form>
    );
  }
};
export default FormLead;