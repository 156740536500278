import React, {useEffect, useState} from 'react';
import { Table, Modal, Form, Select, Button, Drawer, Layout, Spin } from 'antd';
import { Auth } from 'aws-amplify';
import { queryLeads, queryLeadsWithFilters, getClosers, warmTransferToCloser } from './utils/dataHooks'
import Notes from './Notes'
import dayjs from 'dayjs';

const ListLeadsWarmtransfer = () => {
  const [open, setOpen] = useState(false);
  const [containeddata, setContainedData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [user, setUser] = useState(null);
  const [sortedInfo, setSortedInfo] = useState({});
  const [isCloserModalOpen, setIsCloserModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [closers, setClosers] = useState(null);
 
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentLead, setCurrentLead] = useState(null);

  useEffect(() => {
    if (!loaded){
      fetchData();
      setLoaded(true)
    }
  }, [loaded]);

  const showCloserModal = () => {
    setIsCloserModalOpen(true);
  }

  const handleLiveCancel = () => {
    form.setFieldValue("closer", null);
    setIsCloserModalOpen(false);
  }

  const onTransfer = () => {
    warmTransferToCloser(currentLead, form.getFieldValue("closer"), selectedRowKeys, data);
    setIsCloserModalOpen(false);
    setLoaded(false);
    setSelectedRowKeys([]);
  }

  const showDrawer = (record) => {
    setOpen(true);
    setContainedData(data.filter((lead) => lead.id === record.id ? lead : null)[0]);
  };

  const handleChange = (pagination, filters, sorter) => {
    if (!loaded){
      fetchData();
    }
    setSortedInfo(sorter);
  };

  const onClose = () => {
    setOpen(false);
    setContainedData({});
  };

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const fetchData = async () => {
    setUser(await Auth.currentAuthenticatedUser());
    const closers = await getClosers();
    var closersList= [];

    closers.forEach((closer) => {
      closersList.push({
        label: closer.name,
        value: closer.id
      });
    });
    setClosers(closersList);
    return await queryLeadsWithFilters (setData, { transferType : {eq: "Warm"},  closerId : {attributeExists: false} });
    // return await queryLeads(setData, null, {eq: "Warm"}, null, {attributeExists: false})//.then(() => {setLoaded(true)});
  }
  const columns = [
    {
      title: 'Last Update',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (a) => dayjs(a).format('DD/MM/YY hh:mm A'),
    },
    {
      title: 'Company Name',
      dataIndex: ['client', 'name'],
      key: ['client', 'name'],
    },
    {
      title: 'Owner Name',
      dataIndex: ['client', 'referentName'],
      key: ['client', 'referentName'],
    },
    {
      title: 'Phone 1',
      dataIndex: ["client", "id"],
      key: "phone1",
    },
    {
      title: 'Phone 2',
      dataIndex: ["client", "phone"],
      key: "phone2",
    },
    {
      title: 'Email',
      dataIndex: ['client', 'email'],
      key: 'email',
      ellipsis: true,
    },
    {
      title: 'Processor',
      dataIndex: ['provider','name'],
      key: 'provider',
      ellipsis: true,
    },
    {
      title: 'Creation Date',
      dataIndex: ['createdAt'],
      key: 'creationdate',
      ellipsis: true,
      render: (a) => dayjs(a).format('DD/MM/YY hh:mm A'),
      sorter: (a, b) =>  {
        return dayjs(b.createdAt).diff(a.createdAt);
      },
      sortOrder: sortedInfo.columnKey === 'creationdate' ? sortedInfo.order : null,
    },
    {
      title: 'Opener',
      dataIndex: ['opener','name'],
      key: 'opener',
      ellipsis: true,
    },
    {
      title: 'Send',
      dataIndex: '',
      key: 'x',
      render: (record) => <a onClick={(event) => {event.stopPropagation();setCurrentLead(record);showCloserModal();}}>Send</a>,
    },
  ];

  if (!loaded){
    return(<Spin size="large" />)
  }
  else{
    data.map((a,k) => {a.key = k});
    data.sort((a, b) => {
      if(a.updatedAt && b.updatedAt) {
        return dayjs(b.updatedAt).diff(a.updatedAt);
      } else if(a.updatedAt) {
        return -1;
      } else {
        return 1;
    }});

    return(
      <>
          <Table
          columns={columns}
          dataSource={data}
          rowSelection={{ ...rowSelection }}
          onChange={handleChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event,data) => {showDrawer(record)}, // click row
            };
          }}
          />
          <Form
            form={form}
            name="control-hooks"
          >
          <Modal title="Assign to a closer" open={isCloserModalOpen} onOk={onTransfer} onCancel={handleLiveCancel}>
              <Form.Item
              name="closer"
            >
            <Select
            placeholder="Select a option and change input text above"
            allowClear
            options={closers}
          ></Select>
          </Form.Item>
          </Modal>
          </Form>
          <Button type="primary" htmlType="submit"  onClick={showCloserModal} disabled={selectedRowKeys.length <= 1}>
            Batch Transfer
          </Button>
          <Drawer
              title={containeddata.name}
              placement="right"
              closable={false}
              onClose={onClose}
              open={open}
              width={800}
              destroyOnClose={true}
      >

        <Layout>
          <Notes leadInfo={{lead: containeddata}} />
        </Layout>
      </Drawer>
      </>
      

    )
  }
};


  
export default ListLeadsWarmtransfer;