import React, { useEffect, useState } from "react";
import { List, Popover, Badge, Calendar, Drawer, Layout } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";


import { delay, queryEventsCalendar } from "./utils/dataHooks";

import { Auth } from "aws-amplify";
import dayjs from "dayjs";
import FormLead_opener from "./Form_opener";
import FormLead_closer from "./Form_closer";
import Notes from "./Notes";
const { Content, Sider } = Layout;
const getListData = (value, data) => {
  let listData = data.filter(
    (item) =>

      dayjs(item.callbackDate).format("YYYY-MM-DD") === value.format("YYYY-MM-DD")
  );
  return listData || [];
};
const getMonthData = (value, data) => {
  let listData = data.filter(
    (item) =>
      dayjs(item.callbackDate).format("YYYY-MM") === value.format("YYYY-MM")
  );
  return listData || [];
};

const getStatus = (date) => {
  const difference = dayjs(date).diff(dayjs()) / 3600000;
  if (difference >= 1) {
    return "success";
  } else if (0 <= difference && difference < 1) {
    return "warning";
  } else if (-24 < difference && difference <= 0) {
    return "error";
  } else {
    return "default";
  }
};

const getFirstDateAndLastDateOnThePanel = (date) => {
  var firstDate = date.startOf("month");
  var lastDate = date.endOf("month");

  const firstDateDay = firstDate.day();
  firstDate = firstDate.subtract(firstDateDay, "day");
  lastDate = lastDate.add(
    42 - Number(lastDate.format("DD")) - firstDateDay,
    "days"
  );

  return {
    firstDate,
    lastDate,
  };
};

const LeadCalendar = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [containeddata, setContainedData] = useState({});

  const [dateRange, setDateRange] = useState(null);
  const [currentDisplay, setCurrentDisplay] = useState("month");
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const showDrawer = (record) => {
    setContainedData(record);
    setIsOpenDrawer(true);
  };

  const onClose = () => {
    setIsOpenDrawer(false);
  };

  useEffect(() => {
    if (!loaded) {
      const getData = async () => {
        await Auth.currentAuthenticatedUser().then(async () => {
          if (!dateRange) {
            const range = getFirstDateAndLastDateOnThePanel(dayjs(Date.now()));
            setDateRange(range);
            await queryEventsCalendar(
              range?.firstDate,
              range?.lastDate,
              setData
            ).then(() => {
              setLoaded(true);
            });
          } else {

            // delay is made to make sure all changes in database are made before fetching new info
            await delay(1000)
            
            await queryEventsCalendar(
              dateRange?.firstDate,
              dateRange?.lastDate,
              setData
            ).then(() => {
              setLoaded(true);
            });
          }
        });
      };
      getData();
    }
  }, [loaded]);


  const monthCellRender = (value) => {
    const listData = getMonthData(value, data);
    return listData?.length > 0 && listData ? (
      <div className="notes-month">
        <section>{listData?.length} leads</section>
      </div>
    ) : null;
  };
  const getStatusColor = (item) => {
    // if (item?.lead?.closer) {
    //   return 'processing'; // blue dot
    // } else {
    //   return 'warning'; // yellow dot
    // }

    if (item?.lead?.score) {
      switch(item?.lead?.score){
        case "Hot":
          return "success" // green dot
        case "Cold":
          return "error" // red dot
        case "Warm":
          return "warning" // yellow dot
      }
      
    } else {
      return 'default'; // grey dot
    }
    // You can add more conditions for other colors as needed
  };
  

  const dateCellRender = (value) => {
    const listData = getListData(value, data);
    return (
      <div>
        <Popover
          content={
            <>
              <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
                <List
                  size="small"
                  bordered
            
                  dataSource={listData}
                  renderItem={(item) => (
                    <List.Item
                      key={item.id}
                      onClick={(event) => {
                        event.stopPropagation();
                        showDrawer(item);
                      }}
                      style={{ cursor: "pointer"}}
                    >
                      <Badge
                        status={getStatusColor(item)}
                        text={
                          dayjs(item.callbackDate).format("HH:mm") +
                          " " +
                          item?.lead?.client?.name
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
            </>
          }
          title={() => {
            return (
              <>
                {/* If we need a close button explicitly on popover */}
                {/* {" "}
                <CloseCircleOutlined 
                  onClick={(event) => {
                    event.stopPropagation();
                    setSelectedDate(null);
                  }}
                />{" "} */}
                Calls
              </>
            );
          }}
          open={
            listData &&
            dayjs(selectedDate).format("YYYY-MM-DD") ===
              dayjs(value).format("YYYY-MM-DD") &&
            !isOpenDrawer
          }
        >
          <Badge count={listData?.length} style={{ cursor: "pointer" }} />
        </Popover>
      </div>
    );
  };
  const cellRender = (currentDate, info) => {
    if (info.type === "date") return dateCellRender(currentDate);
    if (info.type === "month") return monthCellRender(currentDate);
    return info.originNode;
  };
  const handleDayClick = (currentDate) => {
    if (
      dayjs(selectedDate).format("YYYY-MM-DD") ===
      dayjs(currentDate).format("YYYY-MM-DD")
    ) {
      setSelectedDate(null);
    } else {
      setSelectedDate(currentDate);
    }
  };

  if (!loaded) {
    return <>Loading...</>;
  } else {
    return (
      <>
        <Calendar
          cellRender={cellRender}
          mode={currentDisplay}
          value={currentDate}
          onPanelChange={(date, mode) => {
            setCurrentDate(date);
            setCurrentDisplay(mode);
            setLoaded(false);
            setDateRange(getFirstDateAndLastDateOnThePanel(date));
          }}
          onSelect={handleDayClick}
        />

        <Drawer
          title={containeddata?.lead?.client?.name || "Lead Details"}
          placement="right"
          closable={true}
          onClose={onClose}
          open={isOpenDrawer}
          getContainer={false}
          width={800}
        >
          <Layout>
                    <Content>
          {containeddata?.lead?.closer ? <FormLead_closer parentData={data} setParentData={setData} setParentContainedData={setContainedData}  leadInfo={containeddata} setLoaded={setLoaded} setIsDrawerOpen={setIsOpenDrawer}/> : <FormLead_opener parentData={data} setParentData={setData} setParentContainedData={setContainedData} leadInfo={containeddata} setLoaded={setLoaded} setIsDrawerOpen={setIsOpenDrawer}/>}
                  {/* <FormLead_opener leadInfo={containeddata} setLoaded={setLoaded} setIsDrawerOpen={setIsOpenDrawer}/> */}
                  {/* <FormLead_opener leadInfo={{lead: containeddata}} setContainedData={setContainedData} loaded={loaded} setLoaded={setLoaded} setOpen={onClose}/> */}
                  </Content>
                  <Sider width={250}
                      style={{
                        background: 'white',
                      }}>
                  <Notes leadInfo={{lead: containeddata?.lead}} />
                </Sider>
                </Layout>
        </Drawer>
      </>
    );
  }
};
export default LeadCalendar;
