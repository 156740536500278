import React, { useState, useEffect, useRef } from "react";
import {
  Upload,
  message,
  Button,
  Steps,
  Table,
  Popconfirm,
  Tooltip,
  Select,
  Progress,
  Spin,
  Space,
  Row,
  Col,
  Statistic,
  Alert,
  Typography,
} from "antd";
import {
  checkClient,
  getProviders,
  handleCreateLead,
  updateLeadStatus,
} from "./utils/dataHooks";

import {
  UploadOutlined,
  DeleteOutlined,
  RedoOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
const { Title } = Typography;

const { Step } = Steps;

const beforeUpload = (file) => {
  const isExcelOrCsv =
    file.type === "application/vnd.ms-excel" ||
    file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    file.type === "text/csv";
  if (!isExcelOrCsv) {
    message.error("You can only upload Excel (.xls, .xlsx) or CSV files!");
    return false;
  }
  const isLt5M = file.size / 1024 / 1024 < 1;
  if (!isLt5M) {
    message.error("File must be smaller than 1MB!");
    return false;
  }
  return true;
};
const red = "#dc3737";
const green = "#4faf15";
const orange = "#FFBF00";

const UploadLeadsAsFile = ({ props }) => {
  const groups =
  props.user.signInUserSession.accessToken.payload["cognito:groups"];
const isOpener = !(groups.includes("Closer") || groups.includes("Admin"))

  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [data, setData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [current, setCurrent] = useState(0);
  const [fileProgress, setFileProgress] = useState(0);
  const [apiProgress, setApiProgress] = useState(0);
  const [failedLeads, setFailedLeads] = useState([]);
  const [invalidRows, setInvalidRows] = useState([]);
  const [targetList, setTargetList] = useState(isOpener? "openerMyLeads":"warm");
  const [apiCallStatuses, setApiCallStatuses] = useState([]); // 'success' or 'fail'
  const refContainer = useRef();

  const [validProviders, setValidProviders] = useState([]);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [statuses, setStatuses] = useState([]);


  useEffect(() => {
    const fetchProviders = async () => {
      const providers = await getProviders();
      var providersList = [];
      var other;
      var noProcessor;
      providers.forEach((provider) => {
        if (provider.name === "Other") {
          other = {
            label: provider.name,
            value: provider.id,
          };
        } else if (provider.name === "No processor") {
          noProcessor = {
            label: provider.name,
            value: provider.id,
          };
        } else {
          providersList.push({
            label: provider.name,
            value: provider.id,
          });
        }
      });

      providersList.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
      providersList.push(other);
      providersList.unshift(noProcessor);
      setValidProviders(providersList);
    };
    fetchProviders();
  }, []);

 
  const reset = () => {
    setLoading(false);
    setFileList([]);
    setData([]);
    setCurrent(0);
    setFileProgress(0);
    setApiProgress(0);
    setFailedLeads([]);
    setInvalidRows([]);
    setDataSource([]);
    setIsDownloaded(false);
    setStatuses([]);
  };

 
  
  let listItems = [];
  if (isOpener){
    listItems = [
      {
        value: "openerMyLeads",
        label: "Opener My Leads List",
      },
    ];
  }
  if (groups.includes("Closer") || groups.includes("Admin")) {
    listItems = [
      {
        value: "warm",
        label: "Warm List",
      },
      {
        value: "closer",
        label: "Closer List",
      },
      {
        value: "revive",
        label: "Revive List",
      },
    ];
  }

  const handleFileRead = async (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), {
        type: "array",
      });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setData(jsonData);

      const validLanguages = ["English", "French", "Other"];
      const phoneNumbers = jsonData
        .slice(1)
        .map((row) => row[2])
        .filter((phone) => phone);

      const validateRow = async (row) => {
        const errors = [];
        if (!row[0]) errors.push("Missing Company Name");
        if (!row[1]) errors.push("Missing Owner Name");
        if (!row[2]) {
          errors.push("Missing Phone 1");
        } else {
          const check = await checkClient(row[2]);
          if (check.check) {
            errors.push(
              "Client with this phone already exists in the database"
            );
          } else {
            const isDuplicate =
              phoneNumbers.filter((phone) => phone === row[2]).length > 1;
            if (isDuplicate) {
              errors.push("Duplicate phone number in file");
            }
          }
        }

        if (row[5] && !validProviders.find((item) => item.label === row[5]))
          errors.push("Invalid Processor");
        if (row[6] && !validLanguages.includes(row[6]))
          errors.push("Invalid Language");
        return errors;
      };

      const modifyData = async () => {
        const total_length = jsonData.slice(1).length
        let counter = 0
        const newDataPromises = jsonData.slice(1).map(async (row, rowIndex) => {
          const rowData = {};
          row.forEach((cell, cellIndex) => {
            rowData[cellIndex.toString()] = cell;
          });
          rowData["7"] = props.user?.attributes?.name;
          const errors = await validateRow(rowData);
          counter = counter + 1 
          setFileProgress(Math.round(((counter) / total_length) * 100) )
          return { key: rowIndex.toString(), ...rowData, errors: errors };
        });

        // Wait for all promises to resolve
        const newData = await Promise.all(newDataPromises);

        setDataSource(newData);
        const rez = newData.filter((row) => row.errors.length > 0);

        setInvalidRows(rez);
        if (!rez.length) {
          setCurrent(2);
        } else {
          setCurrent(1);
        }
      };

      modifyData();
    };
    reader.readAsArrayBuffer(file);
  };

  const handleBeforeUpload = (file) => {
    const valid = beforeUpload(file);
    if (valid) {
      setLoading(true);
      handleFileRead(file);
    }
    return false; // Prevent automatic upload
  };


  const deleteErrorRows = () => {
    setDataSource(dataSource.filter((row) => !invalidRows.includes(row)));
  };
  const headers = [
    "Company Name",
    "Owner Name",
    "Phone 1",
    "Phone 2",
    "Email",
    "Processor",
    "Language",
    "errors",
  ];

  const processDataToDownload = (rows) => {
    return rows.map((row) => {
      const transformedRow = {
        "Company Name": row["0"] || "",
        "Owner Name": row["1"] || "",
        "Phone 1": row["2"] || "",
        "Phone 2": row["3"] || "",
        Email: row["4"] || "",
        Processor: row["5"] || "",
        Language: row["6"] || "",
        errors: row.errors.join(", ") || "",
      };
      return headers.map((header) => transformedRow[header] || "");
    });
  };

  const createLead = async (lead) => {
    let leadinfo = {
      entreprise: lead[0],
      nom: lead[1],
      tel: lead[2],
    };

    if (lead[3]) leadinfo["tel2"] = lead[3];
    if (lead[4]) leadinfo["email"] = lead[4];
    if (lead[5]) {
      leadinfo["fournisseur"] = validProviders.find(
        (value) => value.label === lead[5]
      ).value;
    }
    if (lead[6]) leadinfo["language"] = lead[6];

    switch (targetList) {
      case "openerMyLeads":
        return handleCreateLead(leadinfo, false);
      case "warm":
        return handleCreateLead(leadinfo, false, "Warm");
      case "closer":
        // closer will be the user who sends request, user must be Closer or Admin
        leadinfo["closer"] = props.user.username;
        return handleCreateLead(leadinfo, false, "Live");
      case "revive":
        leadinfo["closer"] = props.user.username;
        const result = await handleCreateLead(leadinfo, false, "Live");
        const inputChangeStatus = {
          lead: {
            id: result.id,
          },
        };
        return updateLeadStatus(inputChangeStatus, "Not interested");
    }

    // const shouldFail = Math.random() > 0.8;
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     if (shouldFail) {
    //       reject("API call failed");
    //     } else {
    //       resolve("API call succeeded");
    //     }
    //   }, 2);
    // });
  };

  const handleSendData = async () => {
    setCurrent(3);
    message.info("Working on it... Don't close the sreen!");
    for (let i = 0; i < dataSource.length; i++) {
      try {
        await createLead(dataSource[i]);
        setStatuses((prev) => [...prev, "success"]);
      } catch (error) {
        dataSource[i] = {
          ...dataSource[i],
          errors: [
            "API failed, try again with the same data or contact developers.",
          ],
        };
        setStatuses((prev) => [...prev, "fail"]);
        setFailedLeads((prev) => [...prev, dataSource[i]]);
      }
      setApiProgress(Math.round(((i + 1) / dataSource.length) * 100));
      setApiCallStatuses([...statuses]);
    }
    message.success("Leads processing completed");
  };

  const columns = [
    {
      title: (
        <span>
          Company Name<span style={{ color: red }}> *</span>
        </span>
      ),
      dataIndex: "0",
      key: "0",
      render: (text, record) => (
        <div
          style={{
            backgroundColor: record.errors.includes("Missing Company Name")
              ? red
              : "transparent",
          }}
        >
          {text ? text : "Missing value"}
        </div>
      ),
    },
    {
      title: (
        <span>
          Owner Name<span style={{ color: red }}> *</span>
        </span>
      ),
      dataIndex: "1",
      key: "1",
      render: (text, record) => (
        <div
          style={{
            backgroundColor: record.errors.includes("Missing Owner Name")
              ? red
              : "transparent",
          }}
        >
          {text ? text : "Missing value"}
        </div>
      ),
    },
    {
      title: (
        <span>
          Phone 1<span style={{ color: red }}> *</span>
        </span>
      ),
      dataIndex: "2",
      key: "2",
      render: (text, record) => (
        <div
          style={{
            backgroundColor:
              record.errors.includes("Missing Phone 1") ||
              record.errors.includes(
                "Client with this phone already exists in the database"
              )
                ? red
                : record.errors.includes("Duplicate phone number in file")
                ? orange
                : "transparent",
          }}
        >
          {text ? text : "Missing value"}
        </div>
      ),
    },
    { title: "Phone 2", dataIndex: "3", key: "3" },
    { title: "Email", dataIndex: "4", key: "4" },
    {
      title: "Processor",
      dataIndex: "5",
      key: "5",
      render: (text, record) => (
        <div
          style={{
            backgroundColor: record.errors.includes("Invalid Processor")
              ? red
              : "transparent",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Language",
      dataIndex: "6",
      key: "6",
      render: (text, record) => (
        <div
          style={{
            backgroundColor: record.errors.includes("Invalid Language")
              ? red
              : "transparent",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: targetList === "closer" ? "Closer Name" : "Opener Name",
      dataIndex: "7",
      key: "7",
      render: () => props.user?.attributes?.name,
    },
  ];

  const handleDownload = () => {
    setIsDownloaded(true);
  };
  return (
    <div ref={refContainer}>
      <Steps current={current}>
        <Step title="Select File" />
        <Step title="Preview Issues" />
        <Step title="Preview Validated" />
        <Step title="Send Data" />
      </Steps>
      <div style={{ marginTop: 24 }}>
        {current === 0 && (
          <>
            <Row
              justify="space-between"
              align="middle"
              style={{ width: "100%" }}
            >
              <Col>
                <Select
                  defaultValue={isOpener? "openerMyLeads":"warm"}
                  style={{
                    minWidth:"50px",
                  }}
                  onChange={(value) => setTargetList(value)}
                  options={listItems}
                  disabled={
                    !(groups.includes("Closer") || groups.includes("Admin"))
                  }
                />
              </Col>
              <Col>
                <CSVLink
                  data={[
                    {
                      "Company Name": "Maple Leaf Solutions",
                      "Owner Name": "John Doe",
                      "Phone 1": "17777777777",
                      "Phone 2": "18888888888",
                      Email: "contact@mapleleafsolutions.ca",
                      Processor: "Elavon",
                      Language: "French",
                    },
                  ]}
                  filename="template.csv"
                >
                  <Button>
                    <DownloadOutlined /> Template
                  </Button>
                </CSVLink>
              </Col>
            </Row>

            <p></p>

            <Upload
              beforeUpload={handleBeforeUpload}
              fileList={fileList}
              onRemove={() => setFileList([])}
              multiple={false}
            >
              <Button icon={<UploadOutlined />}>
                Upload CSV or Excel File
              </Button>
            </Upload>

            {loading && <Progress percent={fileProgress} />}
          </>
        )}
        {current === 1 && (
          <>
            <Row
              justify="space-between"
              align="middle"
              style={{ width: "100%" }}
            >
              <Col>
                <Title style={{ marginTop: 25 }}>Rows with errors</Title>
              </Col>
              <Col>
                <Button onClick={reset} style={{ marginTop: 25 }}>
                  <RedoOutlined /> Reset
                </Button>
              </Col>
            </Row>
            <p>
              {" "}
              You don't need to do anything. You will be able to download and
              correct them later.
            </p>

            <Table
              columns={columns}
              dataSource={dataSource.filter((record) =>
                record?.errors.length ? true : false
              )}
              pagination={{ pageSize: 6 }}
              rowClassName={(record) =>
                record.errors.length > 0 ? "table-row-error" : ""
              }
              rowKey="key"
              onRow={(record) => {
                const errors = record.errors;
                return {
                  className: errors.length > 0 ? "table-row-error" : "",
                  title: errors.join(", "),
                };
              }}
              style={{ marginTop: 20 }}
              scroll={{ x: true }}
            />
            <Button
              onClick={() => {
                deleteErrorRows();
                setCurrent(2);
              }}
              type="primary"
              style={{ marginTop: 16 }}
            >
              Next
            </Button>
          </>
        )}

        {current === 2 &&
          (dataSource.length > 0 ? (
            <>
              <Row
                justify="space-between"
                align="middle"
                style={{ width: "100%" }}
              >
                <Col>
                  <Title style={{ marginTop: 25 }}>
                    {" "}
                    {
                      listItems.find((value) => {
                        return value.value === targetList;
                      }).label
                    }{" "}
                  </Title>
                </Col>
                <Col>
                  <Button onClick={reset} style={{ marginTop: 25 }}>
                    <RedoOutlined /> Reset
                  </Button>
                </Col>
              </Row>
              <p> These valid rows will be added to CRM.</p>
              <Table
                columns={columns}
                dataSource={dataSource.filter((record) =>
                  record?.errors.length ? false : true
                )}
                pagination={{ pageSize: 6 }}
                rowClassName={(record) =>
                  record.errors.length > 0 ? "table-row-error" : ""
                }
                rowKey="key"
                onRow={(record) => {
                  const errors = record.errors;
                  return {
                    className: errors.length > 0 ? "table-row-error" : "",
                    title: errors.join(", "),
                  };
                }}
                style={{ marginTop: 20 }}
                scroll={{ x: true }}
              />

              <Space>
                {
                  <Button
                    onClick={() => {
                      deleteErrorRows();
                      handleSendData();
                    }}
                    type="primary"
                    style={{ marginTop: 16 }}
                  >
                    Send Data
                  </Button>
                }
              </Space>
            </>
          ) : invalidRows.length > 0 ? (
            <>
              {" "}
              There are no valid rows in your file. Please correct them and try
              again. <br></br>
              <Button
                onClick={reset}
                style={{ marginTop: 75 }}
                icon={<RedoOutlined />}
              >
                {" "}
                Start Uploading Again
              </Button>
            </>
          ) : (
            <Spin />
          ))}
        {current === 3 && (
          <>
            {apiProgress < 100 && <Progress percent={apiProgress} />}

            <Row
              justify="space-between"
              align="middle"
              style={{ width: "100%", marginTop: 50 }}
            >
              <Col style={{ textAlign: "center", flex: 1 }}>
                <Statistic
                  title="Processed:"
                  value={invalidRows.length + statuses.length}
                  precision={0}
                />
              </Col>
              <Col style={{ textAlign: "center", flex: 1 }}>
                <Statistic
                  title="Succeeded:"
                  value={
                    statuses.filter((status) => status === "success").length
                  }
                  precision={0}
                  valueStyle={{
                    color: green,
                  }}
                />
              </Col>
              <Col style={{ textAlign: "center", flex: 1 }}>
                <Statistic
                  title="Failed:"
                  value={invalidRows.length + failedLeads.length}
                  precision={0}
                  valueStyle={{
                    color: red,
                  }}
                />
              </Col>
            </Row>
            {apiProgress === 100 &&
              (invalidRows.length > 0 || failedLeads.length > 0) && (
                <Alert
                  style={{ marginTop: 75 }}
                  message={`There ${
                    invalidRows.length + failedLeads.length > 1 ? "are" : "is"
                  } ${invalidRows.length + failedLeads.length} ${
                    invalidRows.length + failedLeads.length > 1
                      ? "errors"
                      : "error"
                  } in your data.`}
                  description={
                    <div>
                      <p>
                        If you want to fix the errors, download the file and
                        correct the mistakes in the data.
                      </p>
                      <CSVLink
                        data={[
                          headers,
                          ...processDataToDownload(invalidRows),
                          ...processDataToDownload(failedLeads),
                        ]}
                        filename="errors.csv"
                        onClick={handleDownload}
                      >
                        <Button style={{ marginTop: 10 }}>
                          <DownloadOutlined /> Download Rows With Errors
                        </Button>
                      </CSVLink>
                    </div>
                  }
                  type="warning"
                  showIcon
                />
              )}

            {apiProgress === 100 &&
              (invalidRows.length > 0 || failedLeads.length > 0 ? (
                isDownloaded ? (
                  <Button
                    onClick={reset}
                    style={{ marginTop: 75 }}
                    icon={<RedoOutlined />}
                  >
                    {" "}
                    Start Uploading Again
                  </Button>
                ) : (
                  <Popconfirm
                    title={
                      "Sure to go back? You will not download rows with errors."
                    }
                    onConfirm={reset}
                  >
                    <Button style={{ marginTop: 75 }} icon={<RedoOutlined />}>
                      {" "}
                      Start Uploading Again
                    </Button>
                  </Popconfirm>
                )
              ) : (
                <Button
                  type="primary"
                  onClick={reset}
                  style={{ marginTop: 75 }}
                >
                  <RedoOutlined /> Start Uploading Again
                </Button>
              ))}
          </>
        )}
      </div>

      {/* <style>{`
        .table-row-error {
          background-color: #dc3737 !important;
        }
      `}</style> */}
    </div>
  );
};

export default UploadLeadsAsFile;
