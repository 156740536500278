import React, { useState, useEffect } from "react";
import {
  UserAddOutlined,
  SplitCellsOutlined,
  FireOutlined,
  FormOutlined,
  FileAddOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Row, Col, Tabs, theme, Space, Popconfirm } from "antd";
import { Auth } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import FormLead from "./Form";
import LeadCalendar from "./Calendar";
import ListLeads from "./ListLeads";
import AuthUser from "./Auth";
import { CreateUser } from "./CreateUser";
import ListLeadsWarmtransfer from "./ListLeads_warmtransfer";
import ListLeadsCloser from "./ListLeads_Closer";
import ListLeads_inReview from "./ListLeads_inReview";
import FormCreateLead from "./Form_CreateLead";
import ListLeadsDuped from "./ListLeads_duped";
import ListLeadsRevive from "./ListLeads_notInterested";
import UploadLeadsAsFile from "./UploadLeadsAsFile";
import HelpButton from "./HelpButton";
const { Header, Content, Sider } = Layout;

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

const App = (props) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [userGroupAdmin, setUserGroupAdmin] = useState("Opener");
  const [selectedMenuItem, setSelectedMenuItem] = useState("3");
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useState([]);
  const [submenuItems, setSubmenuItems] = useState([]);

  const user = props.user;

  // Returns an array of groups
  const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

  const opener_menu_items = [
    { key: 2, label: "Form" },
    { key: 3, label: "Calendar" },
    { key: 4, label: "List" },
  ];
  let mainMenuItems;

  if (groups.includes("Admin")) {
    mainMenuItems = [
      ...opener_menu_items,
      { key: 5, label: "Admin Users" },
      { key: 6, label: "Duped Leads List" },
      { key: 7, label: "Warm List Transfer" },
      { key: 8, label: "Review List" },
      { key: 9, label: "Revive List" },
    ];
  } else {
    mainMenuItems = [...opener_menu_items];
  }
  const keysMenuItemsWithSubmenus = [2, 4, 5];
  const submenuArrayLeadsList = [
    {
      key: "subOpenerLeads",
      icon: React.createElement(SplitCellsOutlined),
      label: "Opener Leads",
    },

  ];
  if (groups.includes("Closer") || groups.includes("Admin")) {
    submenuArrayLeadsList.push({
      key: "subCloserLeads",
      icon: React.createElement(FireOutlined),
      label: "Closer Leads",
    });
  }

  let submenuArrayCreateLeads = [
    {
      key: "subCreateLead",
      icon: React.createElement(FormOutlined),
      label: "Form",
    },
    
  ]
  if(!process.env.REACT_APP_UPLOAD_LEADS_FROM_FILE || process.env.REACT_APP_UPLOAD_LEADS_FROM_FILE === "true"){
    submenuArrayCreateLeads = [...submenuArrayCreateLeads, 
      {
        key: "subUploadFiles",
        icon: React.createElement(FileAddOutlined),
        label: "From file",
      },
    ]

  }

  const handleMainMenuItemClick = (e) => {
    if (selectedMenuItem === e.key) {
      setSelectedMenuItem("");
    }
    setSelectedMenuItem(e.key);
    // Reset the submenu selection when a main menu item is clicked
    setSelectedSubMenuItem([]);

    switch (e.key) {
      case "2":
        setSelectedSubMenuItem("subCreateLead");
        setSubmenuItems(submenuArrayCreateLeads);
        break;

      case "4":
        setSelectedSubMenuItem("subOpenerLeads");
        setSubmenuItems(submenuArrayLeadsList);
        break;
      case "5":
        setSubmenuItems([
          {
            key: "subAdmCreateUser",
            icon: React.createElement(UserAddOutlined),
            label: "Create User",
          },
        ]);
        break;
      default:
        setSubmenuItems([]);
        break;
    }
  };

  const handleSubMenuItemClick = (e) => {
    // No need to update submenuItems here since it's already set based on the main menu selection
    setSelectedSubMenuItem(e.key);
  };

  const onChangeTab = (key, value) => {
    setUserGroupAdmin(key);
  };

  useEffect(() => {
    setSelectedMenuItem("3");
  }, []);

  const groupTabs = ["Opener", "Closer", "Admin"];
  // Pass a function to map
  const items = groupTabs.map((a) => {
    return {
      key: a,
      label: a,
      children: (
        <AuthUser
          group={userGroupAdmin}
          setUserGroupAdmin={setUserGroupAdmin}
        />
      ),
    };
  });

  const componentsSwtich = (key) => {
    switch (key) {
      case "1":
        signOut();
        return;
      case "2":
        return (
          <FormCreateLead user={props.user} />
          // <FormLead user={props.user}/>
        );
      case "subCreateLead":
        return (
          <FormCreateLead user={props.user} />
          // <FormLead user={props.user}/>
        );
      case "subUploadFiles":
        return (
          <UploadLeadsAsFile props={props} />
          // <FormLead user={props.user}/>
        );

      case "3":
        return <LeadCalendar />;
      // return (<CalendarViewWeekly />);
      case "4":
        return <ListLeads />;
      case "subOpenerLeads":
        return <ListLeads />;
      case "subCloserLeads":
        return <ListLeadsCloser />;
      case "5":
        return (
          <Tabs
            type="card"
            activeKey={userGroupAdmin}
            items={items}
            onChange={onChangeTab}
          />
        );
      case "subAdmCreateUser":
        return <CreateUser />;
      case "6":
        return <ListLeadsDuped />;
      case "7":
        return <ListLeadsWarmtransfer />;
      case "8":
        return <ListLeads_inReview />;
      case "9":
        return <ListLeadsRevive />;
      default:
        break;
    }
  };
  return (
    <Layout>
      <Header>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} wrap={false}>
          {process.env.REACT_APP_LOGO &&
           <Col flex="none">
           <img
            src={"logo.png"}
            //  src={process.env.REACT_APP_LOGO}
             alt="Logo"
            //  TODO: what is the right dimension for Logo?
            style={{
              padding: "5px",
              maxWidth: "150px",
              maxHeight: "64px",
              width: "auto",
              height: "auto",
              objectFit: "contain"
            }}
        
           />
         </Col>}
          <Col flex="auto">
            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={["3"]}
              selectedKeys={[selectedMenuItem]}
              onClick={handleMainMenuItemClick}
              items={mainMenuItems}
              style={{ lineHeight: "64px" }}
            />
          </Col>
          {/* <Col flex="none" style={{ textAlign: "right" }}>
            <Menu
              mode="horizontal"
              theme="dark"
              style={{ lineHeight: "64px" }}
              items={[{ key: 1, label: "Logout" }]}
              onClick={handleSubMenuItemClick}
            />
          </Col> */}
          <Col>
            <Space>
            <Popconfirm 
                description="You are about to sign out, are you sure?"
                onConfirm={signOut}
            
            >
              <LoginOutlined 
                  style={{ fontSize: '30px', color: 'white' }}
              />
            </Popconfirm>
            <HelpButton user={user}/>

            </Space>
          </Col>
        </Row>
      </Header>

      <Layout>
        {keysMenuItemsWithSubmenus.includes(Number(selectedMenuItem)) && (
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
            }}
          >
            <Menu
              mode="inline"
              style={{
                height: "100%",
                borderRight: 0,
              }}
              selectedKeys={[selectedSubMenuItem ?? selectedMenuItem]}
              items={submenuItems}
              onClick={handleSubMenuItemClick}
            />
          </Sider>
        )}
        <Layout
          style={{
            padding: "0 24px 24px",
          }}
        >
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
            }}
          >
            {componentsSwtich(
              selectedSubMenuItem.length > 0
                ? selectedSubMenuItem
                : selectedMenuItem
            )}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default App;
