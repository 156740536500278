import React, { useState, useEffect } from "react";
import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  Table,
  Select,
  Spin,
  Popconfirm,
  message,
  Typography,
  Modal,
  Alert,
} from "antd";
import { listEditors } from "./utils/CognitoHook";
import { mutationUpdateUser, queryEnabledUsers } from "./utils/dataHooks";
import { UserDeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { changeGroup, DeleteUser, EnableUser } from "./utils/CognitoHook";

import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

function AuthUser(props) {
  const [usertable, setUsertable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [canEnable, setCanEnable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    const fetchUsers = async () => {
      const users = await queryEnabledUsers();
      if (!process.env.REACT_APP_MAX_USERS) {
        //if there is no env variables, one can enable unlimited users
        setCanEnable(true);
      } else if (Number(process.env.REACT_APP_MAX_USERS) > users.length) {
        setCanEnable(true);
      } else {
        setCanEnable(false);
      }

      const fetchedUsers = await listEditors(props.group);
      const formattedUsers = fetchedUsers.Users.map((u) => {
        const findAttribute = (attrName) => {
          return u.Attributes.find(attr => attr.Name === attrName)?.Value;
        };
      
        return {
          enabled: u.Enabled,
          key: findAttribute('sub'), 
          name: findAttribute('name'),
          email: findAttribute('email')
        };
      });

      setUsertable(formattedUsers);
      setLoading(false);
    };
    if (loading) fetchUsers();
  }, [props.group, loading]);

  const { Text, Link } = Typography;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => {
        if (!record.enabled) {
          return <Text delete>{text}</Text>;
        } else {
          return text;
        }
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text, record) => {
        if (!record.enabled) {
          return <Text delete>{text}</Text>;
        } else {
          return text;
        }
      },
    },
    {
      title: "Group",
      render: (text) => (
        <Select
          defaultValue={props.group}
          style={{
            width: 160,
          }}
          onChange={(a) => ChangeGroups(a, text)}
          options={[
            {
              value: "Opener",
              label: "Opener",
            },
            {
              value: "Closer",
              label: "Closer",
            },
            {
              value: "Admin",
              label: "Admin",
            },
          ]}
        />
      ),
    },
    {
      title: "",
      dataIndex: "key",
      render: (text, record) => {
        if (record.enabled) {
          return (
            <Popconfirm
              title="Disable the user"
              description="Are you sure to disbale this user?"
              onConfirm={(a) => ConfirmDeleteUser(record.key)}
              okText="Yes"
              cancelText="No"
            >
              <UserDeleteOutlined
                style={{ fontSize: "20px", color: "#e94133" }}
              />
            </Popconfirm>
          );
        } else {
          if (canEnable)
            return (
              <Popconfirm
                title="Enable the user"
                description="Are you sure to enable this user?"
                onConfirm={(a) => enableUser(record.key)}
                okText="Yes"
                cancelText="No"
              >
                <UserAddOutlined
                  style={{ fontSize: "20px", color: "#12a41d" }}
                />
              </Popconfirm>
            );
          else {
            return (
              <UserAddOutlined
                onClick={() => setIsModalOpen(true)}
                style={{ fontSize: "20px", color: "#12a41d" }}
              />
            );
          }
        }
      },
    },
  ];

  function enableUser(user) {
    mutationUpdateUser(user, { id: user, status: "enabled" });
    EnableUser(user).then((a) => {
      const result = usertable.map((item) =>
        item.key === user ? { ...item, enabled: true } : item
      );
      setUsertable(result);

      message.success("The User was enabled.");
      setLoading(true);
    });
  }

  const ConfirmDeleteUser = (user) => {
    mutationUpdateUser(user, { id: user, status: "disabled" });

    DeleteUser(user).then((a) => {
      const result = usertable.map((item) =>
        item.key === user ? { ...item, enabled: false } : item
      );
      setUsertable(result);
      message.success("The User was disabled.");
      setLoading(true);
    });
  };

  const ChangeGroups = (value, user) => {
    setLoading(true);
    changeGroup(user.key, props.group, value).then((a) => {
      props.setUserGroupAdmin(value);
      mutationUpdateUser(user.key, { id: user.key, role: value });
    });
  };

  if (loading) {
    return <Spin size="large" />;
  } else {
    return (
      <>
        <Modal
          title="Warning"
          open={isModalOpen}
          footer={null}
          onCancel={() => setIsModalOpen(false)}
        >
          <Alert
            style={{}}
            message={`You have reached the limit of ${process.env.REACT_APP_MAX_USERS} enabled users.`}
            description={
              <p>
                To add a new user, please contact support to upgrade your plan
                or deactivate existing users.
              </p>
            }
            type="warning"
            showIcon
          />
        </Modal>
        <Table columns={columns} dataSource={usertable} loading={loading} />
      </>
    );
  }
}

export default withAuthenticator(AuthUser);
