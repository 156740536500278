import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  DatePicker,
  Layout,
  Modal,
  Spin,
  Descriptions,
  message,
  Radio,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { Auth } from "aws-amplify";
import {
  checkClient,
  handleCreateLead,
  handleDuplicateRequest,
  mutationCreateNote,
  getProviders,
  getClosers,
} from "./utils/dataHooks";
const { TextArea } = Input;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 20 },
};

const FormCreateLead = (props) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCloserModalOpen, setIsCloserModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [clientCheck, setClientCheck] = useState({
    dupeFlag: false,
    clientData: null,
  });
  const [disableButtons, setDisableButtons] = useState(true);
  const [providers, setProviders] = useState(null);
  const [closers, setClosers] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tempClientData, setTempClientData] = useState(null);
  const [isDisabledInputs, setIsDisabledInputs] = useState(false);
  const [isClientDataUnavailable, setIsClientDataUnavailable] = useState(false);  

  useEffect(() => {
    const fetchProviders = async () => {
      const providers = await getProviders();
      const closers = await getClosers();
      setUser(await Auth.currentAuthenticatedUser());
      var providersList = [];
      var closersList = [];
      var other;
      var noProcessor;
      providers.forEach((provider) => {
        if (provider.name === "Other") {
          other = {
            label: provider.name,
            value: provider.id,
          };
        } else if (provider.name === "No processor") {
          noProcessor = {
            label: provider.name,
            value: provider.id,
          };
        } else {
          providersList.push({
            label: provider.name,
            value: provider.id,
          });
        }
      });
      closers.forEach((closer) => {
        closersList.push({
          label: closer.name,
          value: closer.id,
        });
      });
      providersList.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
      providersList.push(other);
      providersList.unshift(noProcessor);
      setProviders(providersList);
      setClosers(closersList);
    };
    fetchProviders();
  }, []);

  useEffect(() => {
    // If a duplicate client is detected, disable input fields to prevent user changes
    if (clientCheck.dupeFlag) {
      setIsDisabledInputs(true);
       // If there is client data associated with the duplicate, make it available for display
      if (clientCheck.clientData) {
        setIsClientDataUnavailable(false);
        // Store the current form data before it gets overwritten by the duplicate client data
        if (!tempClientData) {
          setTempClientData({
            email: form.getFieldValue("email"),
            nom: form.getFieldValue("nom"),
            entreprise: form.getFieldValue("entreprise"),
            tel2: form.getFieldValue("tel2"),
          });
        }
         // Get the current form values to check against the duplicate client data
        const currentValues = form.getFieldsValue();
        // Update the form only if the duplicate client data is different to prevent unnecessary renders
        if (
          currentValues.email !== clientCheck.clientData.email ||
          currentValues.nom !== clientCheck.clientData.referentName ||
          currentValues.entreprise !== clientCheck.clientData.name ||
          currentValues.tel2 !== clientCheck.clientData.phone
        ) {
          form.setFieldsValue({
            email: clientCheck.clientData.email,
            nom: clientCheck.clientData.referentName,
            entreprise: clientCheck.clientData.name,
            tel2: clientCheck.clientData.phone,
          });
        }
      } else {
        // If there is no client data, indicate that client data is unavailable
        setIsClientDataUnavailable(true);
      }
    } else {
      // If no duplicate is detected, enable inputs and indicate client data is available
      setIsDisabledInputs(false);
      setIsClientDataUnavailable(false);
      // If there was temporary client data stored, reset the form values to it and clear the temporary data to prevent future unintended use
      if (tempClientData) {
        form.setFieldsValue(tempClientData);
        setTempClientData(null);
      }
    }
  }, [clientCheck]);

  useEffect(() => {
    if (isClientDataUnavailable) {
      message.error(
        "There is a duplicated item in database without saved client info. Please contact your administrator."
      );
    }
  }, [isClientDataUnavailable]);

  const checkPhone = (e) => {
    const newPhone = e.target.value;
    if (newPhone) {
      checkClient(newPhone).then((result) => {
        setClientCheck({
          dupeFlag: result.check,
          clientData: result.clientData,
        });
      });
    }
  };

  const inputwidth = 350;

  const showCallBackModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showCloserModal = () => {
    setIsCloserModalOpen(true);
  };

  const handleLiveCancel = () => {
    form.setFieldValue("closer", null);
    setIsCloserModalOpen(false);
  };

  const onCallBack = () => {
    setIsLoading(true);
    messageApi.info(
      "Saving... If you go somewhere else, changes might not be saved."
    );

    handleCreateLead(form.getFieldsValue(), true).then((lead) =>
      addNote(lead, form.getFieldValue().notes)
    );
    setIsModalOpen(false);
  };

  const onWarmTransfer = () => {
    handleCreateLead(form.getFieldsValue(), false, "Warm").then((lead) =>
      addNote(lead, form.getFieldValue().notes)
    );
  };

  const onLiveTransfer = () => {
    handleCreateLead(form.getFieldsValue(), false, "Live").then((lead) =>
      addNote(lead, form.getFieldValue().notes)
    );
    setIsCloserModalOpen(false);
  };
  
  const onRequestDuped = () => {
    handleDuplicateRequest(form.getFieldsValue()).then((leadInfo) => {
      addNote(leadInfo, form.getFieldValue().notes);
      setClientCheck({ dupeFlag: false, clientData: null }); // Reset clientCheck after handling duplicate request
    });
  };

  const addNote = (lead, notes) => {
    if (notes) {
      mutationCreateNote(lead.id, notes);
    }
    form.resetFields();
    if (lead?.errors) {
      message.error("Something went wrong");
    } else {
      messageApi.info("Success");
      setIsLoading(false);
    }
  };

  // if the props is user ('Form' page) it shows his name
  const readonlyitems = [
    {
      key: "1",
      label: "Lead Creator",
      children: props.user?.attributes?.name,
    },
  ];

  const onChange = (value, dateString) => {
    form.setFieldValue("callbackDate", value);
  };

  const onOk = (value) => {
    form.setFieldValue("callbackDate", value);
  };

  //Disable buttons if nom, entreprise and tel are not filled
  const onValuesChange = (changedValues, values) => {
    if (
      values.nom != undefined &&
      values.nom !== "" &&
      values.entreprise != undefined &&
      values.entreprise !== "" &&
      values.tel != undefined &&
      values.tel !== ""
    ) {
      setDisableButtons(false);
    } else {
      setDisableButtons(true);
    }
  };
  if (!providers) return <Spin size="large" />;
  else {
    return (
      <Form
        {...layout}
        form={form}
        name="control-hooks"
        onValuesChange={onValuesChange}
      >
        <Layout style={{ background: "white" }}>
          {contextHolder}
          <Content>
            <Form.Item>
              <Descriptions title="Lead" items={readonlyitems} />
            </Form.Item>
            <Form.Item
              name="entreprise"
              label="Company name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ width: inputwidth }}
                disabled={isDisabledInputs}
              />
            </Form.Item>
            <Form.Item
              name="nom"
              label="Owner Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ width: inputwidth }}
                disabled={isDisabledInputs}
              />
            </Form.Item>
            <Form.Item
              name="tel"
              label="Phone Number"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} onChange={checkPhone} />
            </Form.Item>
            <Form.Item
        name="score"
        label="Score"
      >
      <Radio.Group>
          <Space direction="horizontal" size="large">
            <Radio.Button value="Hot" style={{ display: 'flex', alignItems: 'center', padding: '0 12px' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: 'green', marginRight: 8 }} />
                Hot
              </span>
            </Radio.Button>
            <Radio.Button value="Warm" style={{ display: 'flex', alignItems: 'center', padding: '0 12px' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: 'orange', marginRight: 8 }} />
                Warm
              </span>
            </Radio.Button>
            <Radio.Button value="Cold" style={{ display: 'flex', alignItems: 'center', padding: '0 12px' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: 'red', marginRight: 8 }} />
                Cold
              </span>
            </Radio.Button>
          </Space>
        </Radio.Group>
      </Form.Item>
            <Form.Item
              name="tel2"
              label="Phone Number 2"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                style={{ width: inputwidth }}
                disabled={isDisabledInputs}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                style={{ width: inputwidth }}
                disabled={isDisabledInputs}
              />
            </Form.Item>
            <Form.Item
              name="fournisseur"
              label="Processor"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                placeholder="Select an option and change input text above"
                style={{ width: inputwidth }}
                allowClear
                options={providers}
              ></Select>
            </Form.Item>
            <Form.Item
              name="language"
              label="Language"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                placeholder="Select an option and change input text above"
                style={{ width: inputwidth }}
                allowClear
                options={["French", "English", "Other"].map((item)=>{return {label:item, value:item}})}
              ></Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.gender !== currentValues.gender
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("gender") === "other" ? (
                  <Form.Item
                    name="customizeGender"
                    label="Customize Gender"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input style={{ width: inputwidth }} />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
            <Form.Item
              name="pos"
              label="POS"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
            {!props?.leadInfo && (
              <Form.Item name="notes" label="Notes">
                <TextArea rows={4} style={{ width: inputwidth }} />
              </Form.Item>
            )}
          </Content>
          <Form.Item {...tailLayout}>
            {!clientCheck.dupeFlag && (
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "0 8px" }}
                onClick={showCallBackModal}
                disabled={clientCheck.dupeFlag || disableButtons || isLoading}
              >
                Set Callback
              </Button>
            )}
            <Modal
              title="Set Callback"
              open={isModalOpen}
              onOk={onCallBack}
              onCancel={handleCancel}
            >
              <Form.Item name="callbackDate" label="Callback Date">
                <Space direction="vertical" size={12}>
                  <DatePicker
                    showTime
                    onChange={onChange}
                    onOk={onOk}
                    minuteStep={5}
                    format={"DD/MM/YY hh:mm A"}
                  />
                </Space>
              </Form.Item>
            </Modal>
            {!clientCheck.dupeFlag && (
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "0 8px" }}
                onClick={onWarmTransfer}
                disabled={disableButtons || isLoading}
              >
                Warm Transfer
              </Button>
            )}
            {!clientCheck.dupeFlag && (
              <Button
                type="primary"
                htmlType="submit"
                onClick={showCloserModal}
                disabled={disableButtons || isLoading}
              >
                Live Transfer
              </Button>
            )}
            <Modal
              title="Assign to a closer"
              open={isCloserModalOpen}
              onOk={onLiveTransfer}
              onCancel={handleLiveCancel}
            >
              <Form.Item
                name="closer"
                initialValue={
                  props.leadInfo?.lead?.closer?.name
                    ? props?.leadInfo?.lead?.closer?.id
                    : null
                }
              >
                <Select
                  placeholder="Select a option and change input text above"
                  style={{ width: inputwidth }}
                  allowClear
                  options={closers}
                ></Select>
              </Form.Item>
            </Modal>
            {clientCheck.dupeFlag && (
              <Popconfirm
                placement="top"
                title={"Request duplicate"}
                description={"Are you sure to request a duplicate lead ?"}
                onConfirm={onRequestDuped}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ margin: "0 8px" }}
                  disabled={
                    disableButtons || isLoading || isClientDataUnavailable
                  }
                >
                  Request Duped Lead
                </Button>
              </Popconfirm>
            )}
          </Form.Item>
        </Layout>
      </Form>
    );
  }
};
export default FormCreateLead;
