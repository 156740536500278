export const getLeadCount = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      updatedAt
      leadsCount
      _version
      _lastChangedAt
    }
  }
`;

export const listUsersStatuses = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        role
        status
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      clientId
      openerId
      closerId
      providerId
      pos
      score
      createdAt
      updatedAt
      closed
      deleted
      transferType
      transferDate
      status
      statusChangeDate
      isVerified
      eventId
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      name
      isCompany
      referentName
      phone
      email
      address
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getEventCalendar = /* GraphQL */ `
  query GetEventCalendar($id: ID!) {
    getEventCalendar(id: $id) {
      id
      _deleted
      callbackDate
      _lastChangedAt
      userId
      _version
    }
  }
`;

export const leadsByOpenerIdAndCreatedAt = /* GraphQL */ `
  query LeadsByOpenerIdAndCreatedAt(
    $openerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByOpenerIdAndCreatedAt(
      openerId: $openerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client {
          id
          name
          isCompany
          referentName
          phone
          email
          address
          language
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        openerId
        opener {
          id
          name
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        closer {
          id
          name
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        provider {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        pos
        score
        createdAt
        updatedAt
        closed
        deleted
        notes {
          items {
            id
            owner
            note
            createdAt
            updatedAt
            leadId
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        tags
        status
        statusChangeDate
        transferType
        transferDate
        isVerified
        eventId
        event {
          id
          type
          eventDate
          callbackDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        leadsCount
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listLeadsCloser = /* GraphQL */ `
  query ListLeads(
    $id: ID
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLeads(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        clientId
        client {
          id
          name
          isCompany
          referentName
          phone
          email
          language
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        openerId
        opener {
          id
          name
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        closerId
        closer {
          id
          name
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        providerId
        provider {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        pos
        score
        createdAt
        updatedAt
        closed
        deleted
        notes {
          items {
            id
            owner
            note
            createdAt
            updatedAt
            leadId
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        tags
        transferType
        transferDate
        status
        statusChangeDate
        isVerified
        eventId
        event {
          id
          type
          eventDate
          callbackDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        leadsCount
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const LeadsByLastChangedAt = `query LeadsByLastChangedAt(
  $lastChangedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLeadFilterInput
  $limit: Int
  $nextToken: String
) {
  leadsByLastChangedAt(
    lastChangedAt: $lastChangedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clientId
      client {
        id
        name
        isCompany
        referentName
        phone
        email
        language
        address
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      openerId
      opener {
        id
        name
        role
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      closerId
      closer {
        id
        name
        role
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      providerId
      provider {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      pos
      score
      createdAt
      updatedAt
      closed
      deleted
      notes {
        items {
          id
          owner
          note
          createdAt
          updatedAt
          leadId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      tags
      status
      statusChangeDate
      transferType
      transferDate
      isVerified
      eventId
      leadsCount
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}`

export const listLeads = /* GraphQL */ `
  query ListLeads(
    $id: ID
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLeads(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        clientId
        client {
          id
          name
          isCompany
          referentName
          phone
          email
          language
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        openerId
        opener {
          id
          name
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        closerId
        closer {
          id
          name
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        providerId
        provider {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        pos
        score
        createdAt
        updatedAt
        closed
        deleted
        notes {
          items {
            id
            owner
            note
            createdAt
            updatedAt
            leadId
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        tags
        transferType
        transferDate
        status
        statusChangeDate
        isVerified
        eventId
        event {
          id
          type
          eventDate
          callbackDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        leadsCount
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const eventCalendarsByLeadIdAndEventDate = /* GraphQL */ `
  query EventCalendarsByLeadIdAndEventDate(
    $leadId: ID!
    $eventDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventCalendarsByLeadIdAndEventDate(
      leadId: $leadId
      eventDate: $eventDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        leadId
        type
        eventDate
        callbackDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;

export const eventCalendarsByUserIdAndEventDate = /* GraphQL */ `
  query EventCalendarsByUserIdAndEventDate(
    $userId: ID!
    $eventDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventCalendarsByUserIdAndEventDate(
      userId: $userId
      eventDate: $eventDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        leadId
        lead {
          id
          client {
            id
            name
            isCompany
            referentName
            phone
            email
            language
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          opener {
            id
            name
            role
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          closer {
            id
            name
            role
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          provider {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          pos
          score
          createdAt
          updatedAt
          closed
          deleted
          notes {
            items {
              id
              owner
              note
              createdAt
              updatedAt
              leadId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          eventId
          event {
            id
            type
            eventDate
            callbackDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          tags
          status
          statusChangeDate  
          transferType
          transferDate
          isVerified
          _version
          _deleted
          _lastChangedAt
        }
        type
        eventDate
        callbackDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;

export const notesByLeadIdAndCreatedAt = /* GraphQL */ `
  query NotesByLeadIdAndCreatedAt(
    $leadId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notesByLeadIdAndCreatedAt(
      leadId: $leadId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        user {
          id
          name
          role
        }
        note
        createdAt
        updatedAt
        leadId
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listProviders = /* GraphQL */ `
  query ListProviders(
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProviders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const providerByName = /* GraphQL */ `
  query ProviderByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    providerByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        role
        createdAt
        updatedAt
        status
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const leadsByClientIdAndCreatedAt = /* GraphQL */ `
  query LeadsByClientIdAndCreatedAt(
    $clientId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByClientIdAndCreatedAt(
      clientId: $clientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        openerId
        closerId
        providerId
        pos
        score
        createdAt
        updatedAt
        closed
        deleted
        tags
        status
        statusChangeDate
        transferType
        isVerified
        eventId
        leadsCount
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;