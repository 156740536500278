import React, { useEffect, useState, useRef } from "react";
import { Button, Space, Table, Drawer, Layout, Input, Typography } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";

import { queryLeadsByUserAsCloser } from "./utils/dataHooks";
import dayjs from "dayjs";
import FormLead_closer from "./Form_closer";
import Notes from "./Notes";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Auth } from "aws-amplify";

const InProgressTable = (props) => {

  const [sortedInfo, setSortedInfo] = useState({});

  // const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const { Title } = Typography;
  const [user, setUser] = useState(null);
  const [filterTransferType, setFilterTransferType] = useState("");
  const [filtercLeadStatus, setFiltercLeadStatus] = useState("");
  const [filterCallback, setFilterCallback] = useState("");
  const leadStatusValuesInProgress = [
    "Incomplete",
    "Approved not installed",
    "Client",
    "In Review",
    "In Progress",
  ];

  useEffect(() => {
    // console.log("props.data table In Progress", props.data);

    const updatedTableData = props.data
      .filter((item) => leadStatusValuesInProgress.includes(item.status) )
      .map((record, index) => ({ ...record, key: index }));
    setTableData(updatedTableData);
    // console.log("changed tableData, tableData", updatedTableData);
  }, [props.data]);

  // const transferTypeValues = ["Live", "Warm"];

  const leadStatusValues = [
    "Incomplete",
    "Approved not installed",
    "Client",
    "In Review",
  ];

  // const isTransferType = (type) => transferTypeValues.includes(type);
  const isLeadStatus = (type) => leadStatusValues.includes(type);
  const isCallbackFilter = (type) => type === "Have callback";

  const handleFilterClick = (type) => {
    // if (isTransferType(type)) {
    //   if (filterTransferType !== type) {
    //     setFilterTransferType(type);
    //   } else setFilterTransferType("");
    // }
    if (isLeadStatus(type)) {
      if (filtercLeadStatus !== type) {
        setFiltercLeadStatus(type);
      } else {
        setFiltercLeadStatus("");
      }
    }
    if (isCallbackFilter(type)){
      if (filterCallback !== type){
        setFilterCallback(type);
      } else {
        setFilterCallback("");
      }
    }
  };


  const filteredData =
    filtercLeadStatus || filterCallback
      ? tableData.filter((item) => {
          let leadStatusCondition = true;
          let haveCallbackCondition = true;

          if (filtercLeadStatus) {
            leadStatusCondition = item.status === filtercLeadStatus;
          }
          if (filterCallback){
            haveCallbackCondition = item?.event?.callbackDate ? true : false
          }

          return  leadStatusCondition && haveCallbackCondition;
        })
      : tableData;



  const handleChange = (pagination, filters, sorter) => {
    if (!props.loaded) {
      props.fetchData();
    }
    setSortedInfo(sorter);
  };

  const showDrawer = (record) => {
    props.setIsOpenDrawer(true);
    props.setContainedData(
      tableData.filter((lead) => (lead.id === record.id ? lead : null))[0]
    );
  };

  const onClose = () => {
    props.setIsOpenDrawer(false);
    props.setContainedData({});
  };


  // if (!props.loaded) {
  //   return <>Loading</>;
  // }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm()
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const searchValue = value.toString().toLowerCase();
      
      // Handle nested dataIndex (e.g., ['client', 'name']) or single dataIndex (e.g., ['id'])
      if (Array.isArray(dataIndex) && dataIndex.length > 1) {
          // Check if the nested properties exist before accessing them
          return dataIndex.reduce((acc, key) => acc && acc[key], record)
          ? record[dataIndex[0]][dataIndex[1]].toString().toLowerCase().includes(searchValue)
          : false;
      } else {
          // Check if the property exists before accessing it
          return record[dataIndex[0]] != null
          ? record[dataIndex[0]].toString().toLowerCase().includes(searchValue)
          : false;
      }
    
},
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "id",
      dataIndex: ["id"],
      key: "id",
      ...getColumnSearchProps(["id"]),
    },
    {
      title: "Company Name",
      dataIndex: ["client", "name"],
      key: "client",
      ...getColumnSearchProps(["client", "name"]),
    },
    {
      title: "Owner name",
      dataIndex: ["client", "referentName"],
      key: "owner",
      ellipsis: true,
      ...getColumnSearchProps(["client", "referentName"]),
    },
    {
      title: "Phone 1",
      dataIndex: ["client", "id"],
      key: "phone",
      ellipsis: true,
      ...getColumnSearchProps(["client", "id"]),
    },
   
    {
      title: "Opener name",
      dataIndex: ["opener", "name"],
      key: "opener",
      ellipsis: true,
      ...getColumnSearchProps(["opener", "name"]),
    },
    
    {
      title: "Transfer Type",
      dataIndex: ["transferType"],
      key: "transfer",
      ellipsis: true,
      filterSearch: true,
    },
    {
      title: "Next callBack",
      dataIndex: ["event", "callbackDate"],
      key: "callbackDate",
      render: (a) => (a ? dayjs(a).format("DD/MM/YY hh:mm A") : null),
      render: (a) => (a ? dayjs(a).format("DD/MM/YY hh:mm A") : null),
      ellipsis: true,
      sorter: (a, b) => {
        if (a && a.event?.callbackDate
          && b && b.event?.callbackDate
          ) {
          return dayjs(b.event.callbackDate
            ).diff(a.event.callbackDate
              );
        } else if (a && a.event?.callbackDate
          ) {
          return -1;
        } else {
          return 1;
        }
      },
      sortOrder: sortedInfo.columnKey === "callbackDate" ? sortedInfo.order : null,
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "leadStatus",
      ellipsis: true,
      filterSearch: true,
    },
  ];

  // tableData.map((record, index) => {
  //   record.key = index;
  // });
  return (
    <>
      <div>
        <Title level={2}> In Progress </Title>
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Space size="middle">
            {/* Warm Transfers, Live Transfers, Incomplete, Approved not installed, Client */}
           
            <Button
              type="primary"
              onClick={() => handleFilterClick("Have callback")}
              ghost={filterCallback}
            >
              Callbacks
            </Button>
            <Button
              type="primary"
              onClick={() => handleFilterClick("In Review")}
              ghost={filtercLeadStatus === "In Review"}
            >
              Review
            </Button>
            <Button
              type="primary"
              onClick={() => handleFilterClick("Incomplete")}
              ghost={filtercLeadStatus === "Incomplete"}
            >
              Incomplete
            </Button>
            <Button
              type="primary"
              onClick={() => handleFilterClick("Approved not installed")}
              ghost={filtercLeadStatus === "Approved not installed"}
            >
              Approved not installed
            </Button>
            <Button
              type="primary"
              onClick={() => handleFilterClick("Client")}
              ghost={filtercLeadStatus === "Client"}
            >
              Client
            </Button>
            
            {/* <ClearOutlined
              onClick={() => {
                setFilterTransferType("");
                setFiltercLeadStatus("");
                setFilterCallback("");
              }}
            /> */}
          </Space>
        </div>
      </div>
      <div>
        <Table
          scroll={{ x: true }} 
          columns={columns}
          dataSource={filteredData}
          onChange={handleChange}
          pagination={{ pageSize: 6 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event, data) => {
                showDrawer(record);
              }, // click row
            };
          }}
        />

        <Drawer
          title={props.containeddata.name}
          placement="right"
          closable={false}
          onClose={onClose}
          open={props.isOpenDrawer}
          width={800}
          destroyOnClose={true}
        >
          <Layout>
            <Content>
              <FormLead_closer
                leadInfo={{ lead: props.containeddata }}
                setContainedData={props.setContainedData}
                loaded={props.loaded}
                setLoaded={props.setLoaded}
                onClose={onClose}
                setIsDrawerOpen={props.setIsOpenDrawer}
              />
            </Content>
            <Sider
              width={250}
              style={{
                background: "white",
              }}
            >
              <Notes leadInfo={{ lead: props.containeddata }} />
            </Sider>
          </Layout>
        </Drawer>
      </div>
    </>
  );
};
export default InProgressTable;
