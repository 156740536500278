import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider, FloatButton, Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import './App.css'

const HelpButton = ({user}) => {
  const [userInfo, setUserInfo] = useState({});
  const [latestConsoleLog, setLatestConsoleLog] = useState('');
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const [consoleLogs, setConsoleLogs] = useState([]);


  // Mock function to get user information
  const fetchUserInfo = () => {
    // Replace this with your actual user info fetching logic
    return {
      name: user.attributes.name,
      email: user.attributes.email,
      // other user info fields
    };
  };

  // Function to convert log arguments to string
  const logToString = (args) => {
    return args.map(arg => {
      if (typeof arg === 'object') {
        try {
          return JSON.stringify(arg);
        } catch (e) {
          return '[Circular]';
        }
      }
      return String(arg);
    }).join(' ');
  };


  useEffect(() => {
    // Fetch user info and set it in state
    const info = fetchUserInfo();
    setUserInfo(info);

    // Set up listeners to capture console logs and errors
    const originalLog = console.log;
    const originalError = console.error;

    console.log = function (...args) {
      setConsoleLogs(prevLogs => [...prevLogs, `LOG: ${logToString(args)}`]);
      originalLog.apply(console, args);
    };

    console.error = function (...args) {
      setConsoleLogs(prevLogs => [...prevLogs, `ERROR: ${logToString(args)}`]);
      originalError.apply(console, args);
    };

    // Clean up the console.log and console.error overrides on unmount
    return () => {
      console.log = originalLog;
      console.error = originalError;
    };
  }, []);

  const handleButtonClick = () => {
    const email = 'support@dattico.com';
    const subject = 'Ticket to Support';
    const body = `
      Describe the problem: 


      User Information:
      Name: ${userInfo.name}
      Email: ${userInfo.email}

      Latest Console Log:
       ${consoleLogs.join('\n')}

      Current Page URL:
      ${currentUrl}
    `;
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  return (
    <>

      <Popconfirm
    description="You are about to create ticket to support, do you want this?"
    onConfirm={handleButtonClick}
    okText="Yes"
    cancelText="No"
  >
     <QuestionCircleOutlined 
     style={{ fontSize: '30px', color: '#08c' }}
     />
  </Popconfirm>

     </>
  );
};

export default HelpButton;
