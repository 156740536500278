import React, { useEffect, useState, useRef } from 'react';
import {Tooltip , Card, Button, Space, Table, Drawer, Spin, Statistic, Row,Col, Layout, Input, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { CaretUpOutlined, CaretDownOutlined, MinusOutlined } from '@ant-design/icons';
import { queryLeads,queryLeadsByUserAsOpener, delay } from './utils/dataHooks'
import dayjs from 'dayjs';
// import FormLead from './Form';
import Notes from './Notes'
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Auth } from 'aws-amplify';
import FormLead_opener from './Form_opener';

const ListLeads = () => {
  const [open, setOpen] = useState(false);
  const [containeddata, setContainedData] = useState({});
  const [filteredInfo, setFilteredInfo] = useState({});
  const [filteredInfoFeed, setFilteredInfoFeed] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [sortedInfoFeed, setSortedInfoFeed] = useState({});

  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const { Title } = Typography;
  const [user, setUser] = useState(null);
  const [wait, setWait] = useState(false)


  useEffect(() => {
    if (!loaded){
      if(wait){
        const wait_to_ensure_db_is_updated = async () => {
          await delay(1000);
        }
        wait_to_ensure_db_is_updated();
      }
      fetchData();
      setLoaded(true);
    }
  }, [loaded]);

  const handleChange = (pagination, filters, sorter) => {
    if (!loaded){
      fetchData();
    }
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const handleChangeFeed = (pagination, filters, sorter) => {
    if (!loaded){
      fetchData();
    }
    setFilteredInfoFeed(filters);
    setSortedInfoFeed(sorter);
  };

  const showDrawer = (record) => {
    setOpen(true);
    setContainedData(data.filter((lead) => lead.id === record.id ? lead : null)[0]);
  };

  const onClose = () => {
    setOpen(false);
    setContainedData({});
  };

  const fetchData = async () => {
    return await Auth.currentAuthenticatedUser().then( async (user) => {
      setUser(user);
      return await queryLeadsByUserAsOpener(setData, user.username).then(() => {setLoaded(true); setWait(true);})
      // return await queryLeads(setData, user.username).then(() => {setLoaded(true)});
    });
  }

  const getSumLead = ({ period, modifier = 0, transferType = null }) => {
    const startPeriod = dayjs().startOf(period).add(modifier, period);
    const endPeriod = dayjs().endOf(period).add(modifier, period);

    return data.filter((lead) => {
      const createdAt = dayjs(lead.createdAt);
      const isWithinPeriod = createdAt >= startPeriod && createdAt <= endPeriod;
      const isTransferTypeMatch = transferType ? lead.transferType === transferType : true;
      const isNotDeleted = !lead.deleted;
  
      return isWithinPeriod && isTransferTypeMatch && isNotDeleted;
    }).length;
  };
  const getSumMonthLead = (monthModifier = 0) => getSumLead({ period: "M", modifier: monthModifier });

const getSumWeekLead = (weekModifier = 0) => getSumLead({ period: "week", modifier: weekModifier });

const getSumDayLead = (dayModifier = 0) => getSumLead({ period: "day", modifier: dayModifier });

const getSumMonthLeadWarm = (monthModifier = 0) => getSumLead({ period: "M", modifier: monthModifier, transferType: "Warm" });

const getSumWeekLeadWarm = (weekModifier = 0) => getSumLead({ period: "week", modifier: weekModifier, transferType: "Warm" });

const getSumDayLeadWarm = (dayModifier = 0) => getSumLead({ period: "day", modifier: dayModifier, transferType: "Warm" });

const getSumMonthLeadLive = (monthModifier = 0) => getSumLead({ period: "M", modifier: monthModifier, transferType: "Live" });

const getSumWeekLeadLive = (weekModifier = 0) => getSumLead({ period: "week", modifier: weekModifier, transferType: "Live" });

const getSumDayLeadLive = (dayModifier = 0) => getSumLead({ period: "day", modifier: dayModifier, transferType: "Live" });



  const getTotalLeads = () => {
    return data.length;
  }

  const getTotalClosedLeads = () => {
    return data.filter((lead) => lead?.status ?? lead?.status === "Client").length;
  }

  if (!loaded){
    return(<Spin size="large" />)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
        {
          if (record[dataIndex[0]] != null && record[dataIndex[0]][dataIndex[1]] != null) 
            return (record[dataIndex[0]][dataIndex[1]].toString().toLowerCase().includes(value.toLowerCase()));
        },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  const columns = [
    {
      title: 'Company Name',
      dataIndex: ['client', 'name'],
      key: 'client',
      ...getColumnSearchProps(['client', 'name']),
    },
    {
        title: 'Owner name',
        dataIndex: ['client', 'referentName'],
        key: 'owner',
        ellipsis: true,
        ...getColumnSearchProps(['client', 'referentName']),
      },
      {
      title: 'Phone 1',
      dataIndex: ['client', 'id'],
      key: 'phone',
      ellipsis: true,
      ...getColumnSearchProps(['client', 'id']),
    },
    {
      title: 'Phone 2',
      dataIndex: ['client', 'phone'],
      key: 'phone2',
      ellipsis: true,
      ...getColumnSearchProps(['client', 'phone']),
    },
    {
      title: 'Email',
      dataIndex: ['client', 'email'],
      key: 'email',
      ellipsis: true,
      ...getColumnSearchProps(['client', 'email']),
    },
    {
      title: 'Processor',
      dataIndex: ['provider','name'],
      key: 'provider',
      ellipsis: true,
      ...getColumnSearchProps(['provider','name']),
    },
    {
      title: 'Opener name',
      dataIndex: ['opener','name'],
      key: 'opener',
      ellipsis: true,
      ...getColumnSearchProps(['opener','name']),
    },
    {
      title: 'Creation Date',
      dataIndex: ['createdAt'],
      key: 'creationdate',
      ellipsis: true,
      render: (a) => dayjs(a).format('DD/MM/YY hh:mm A'),
      sorter: (a, b) =>  {
        return dayjs(b.createdAt).diff(a.createdAt);
      },
      sortOrder: sortedInfo.columnKey === 'creationdate' ? sortedInfo.order : null,
    },
    {
      title: 'Last Update',
      dataIndex: ['updatedAt'],
      key: 'updateddate',
      render: (a) => dayjs(a).format('DD/MM/YY hh:mm A'),
      ellipsis: true,
      sorter: (a, b) =>  {
        return dayjs(b.updatedAt).diff(a.updatedAt);
    },
    sortOrder: sortedInfo.columnKey === 'updateddate' ? sortedInfo.order : null,
    },
    {
      title: 'Next callBack',
      dataIndex: ['event','eventDate'],
      key: 'eventdate',
      render: (a) => a ? dayjs(a).format('DD/MM/YY hh:mm A') : null,
      render: (a) => a ? dayjs(a).format('DD/MM/YY hh:mm A') : null,
      ellipsis: true,
      sorter: (a, b) =>  {
        if(a && a.event?.eventDate && b && b.event?.eventDate) {
          return dayjs(b.event.eventDate).diff(a.event.eventDate);
        } else if(a && a.event?.eventDate) {
          return -1;
        } else {
          return 1;
      }},
    sortOrder: sortedInfo.columnKey === 'eventdate' ? sortedInfo.order : null,      
    },
    // {
    //   title: 'Transfer Type',
    //   dataIndex: ['transferType'],
    //   key: 'transfer',
    //   sorter: (a, b) =>  {
    //     if(a && a.transferType && b && b.transferType) {
    //       return a.transferType.localeCompare(b.transferType);
    //     } else if(a && a.transferType) {
    //       return -1;
    //     } else if(b && b.transferType) {
    //       return 1;
    //   }},
    //   sortOrder: sortedInfo.columnKey === 'transfer' ? sortedInfo.order : null,
    //   ellipsis: true,
    // },
  ];

  data.map((record, index) => {record.key = index});

  const columnsFeed = [
    {
      title: 'Company Name',
      dataIndex: ['client', 'name'],
      key: 'client',
      ...getColumnSearchProps(['client', 'name']),
    },
    {
        title: 'Owner name',
        dataIndex: ['client', 'referentName'],
        key: 'owner',
        ellipsis: true,
        ...getColumnSearchProps(['client', 'referentName']),
      },
      {
      title: 'Phone',
      dataIndex: ['client', 'id'],
      key: 'phone',
      ellipsis: true,
      ...getColumnSearchProps(['client', 'id']),
    },
    {
      title: 'Email',
      dataIndex: ['client', 'email'],
      key: 'email',
      ellipsis: true,
      ...getColumnSearchProps(['client', 'email']),
    },
    {
      title: 'Transfer Type',
      dataIndex: ['transferType'],
      key: 'transfer',
      ellipsis: true,
      filters: [
        {
          text: "Warm",
          value: "Warm"
        },
        {
          text: "Live",
          value: "Live"
        },
        {
          text: "Delete",
          value: "Delete"
        },
      ],
      onFilter: (value, record) => record.transferType === value,
      filterSearch: true,
    },
    {
      title: 'Opener name',
      dataIndex: ['opener','name'],
      key: 'opener',
      ellipsis: true,
      ...getColumnSearchProps(['opener','name']),
    },
    {
      title: 'Transfer Date',
      dataIndex: ['transferDate'],
      key: 'transferDate',
      render: (a) => a ? dayjs(a).format('DD/MM/YY hh:mm A') : null,
      ellipsis: true,
      /*sorter: (a, b) =>  {
        if(a.transferDate && b.transferDate) {
          return dayjs(b.transferDate).diff(a.transferDate);
        } else if(a.transferDate) {
          return -1;
        } else {
          return 1;
      }},
      sortOrder: "ascend",*/     
    },
  ];

  const dataFeed = data.filter((record) => record.transferType === "Warm" || record.transferType === "Live" || record.deleted === true);
  dataFeed.sort((a, b) => {
    if(a.transferDate && b.transferDate) {
      return dayjs(b.transferDate).diff(a.transferDate);
    } else if(a.transferDate) {
      return -1;
    } else {
      return 1;
  }});

  data.sort((a, b) => {
    if(a.event?.eventDate && b.event?.eventDate) {
      return dayjs(b.event.eventDate).diff(a.event.eventDate);
    } else if(a.event?.eventDate) {
      return -1;
    } else {
      return 1;
  }});
  const GroupedStatistics = () => {
    return (
      <div style={{ margin: '16px', textAlign: 'center' }}>
        <Row gutter={[16, 16]}>
  
          {/* Monthly Statistics */}
          <Col span={4}>
          <Card title="Monthly Statistics">
              <Statistic title="Warm/Live" value={`${getSumMonthLeadWarm()}/${getSumMonthLeadLive()}`} />
              
              <Statistic title="Total" value={getSumMonthLead()} />

              </Card>
          </Col>
  
          {/* Weekly Statistics */}
          <Col span={4}>
          <Card title="Weekly Statistics">
          <Statistic title="Warm/Live" value={`${getSumWeekLeadWarm()}/${getSumWeekLeadLive()}`} />
              <Statistic title="Total" value={getSumWeekLead()} />
              </Card>
          </Col>
  
          {/* Daily Statistics */}
          <Col span={4}>

          <Card title="Daily Statistics">
              <Statistic title="Warm/Live" value={`${getSumDayLeadWarm()}/${getSumDayLeadLive()}`} />
              <Statistic title="Total" value={getSumDayLead()} />
              </Card>

          </Col>


          {/* Summary */}
          <Col span={4}>
          <Statistic title="Total closed" value={`${getTotalClosedLeads()}`} />
          {/* <Statistic title="Total closed/Total Leads" value={`${getTotalClosedLeads()}/${getTotalLeads()}`} /> */}
          </Col>
  
        </Row>
      </div>
    );
  };
  
  
  
  return (
    <>
    {GroupedStatistics()}

      <div>
      <Title level={2}> Your Leads </Title>
      <Table columns={columns} dataSource={data.filter((record) => record.transferType !== "Warm" && record.transferType !== "Live" && record.transferType !== "Revive" && record.transferType !== "Request Dupe" && record.deleted !== true)} onChange={handleChange} pagination={{ pageSize: 6 }} onRow={(record, rowIndex) => {
        return {
          onClick: (event,data) => {showDrawer(record)}, // click row
        };
      }} />
  <Title level={2}> Transfer History </Title>
  <Table columns={columnsFeed} dataSource={dataFeed} pagination={{ pageSize: 6 }} onChange={handleChangeFeed} />
        <Drawer
              title={containeddata.name}
              placement="right"
              closable={false}
              onClose={onClose}
              open={open}
              width={800}
              destroyOnClose={true}
      >

        <Layout>
                    <Content>
                  <FormLead_opener leadInfo={{lead: containeddata}} 
                  setContainedData={setContainedData} 
                  loaded={loaded} 
                  setLoaded={setLoaded} 
                  setOpen={onClose}
                  setIsDrawerOpen={setOpen}
                  />
                  </Content>
                  <Sider width={250}
                      style={{
                        background: 'white',
                      }}>
                  <Notes leadInfo={{lead: containeddata}} />
                </Sider>
                </Layout>
      </Drawer>
</div>
    </>
  );
};
export default ListLeads;