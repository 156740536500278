export const createLead = /* GraphQL */ `
  mutation CreateLead(
    $input: CreateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    createLead(input: $input, condition: $condition) {
      id
      clientId
      openerId
      closerId
      providerId
      pos
      score
      createdAt
      updatedAt
      closed
      deleted
      notes {
        items {
          id
          owner
          note
          createdAt
          updatedAt
          leadId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      tags
      transferType
      transferDate
      isVerified
      _version
    }
  }
`;

export const updateEventCalendar = /* GraphQL */ `
  mutation UpdateEventCalendar(
    $input: UpdateEventCalendarInput!
    $condition: ModelEventCalendarConditionInput
  ) {
    updateEventCalendar(input: $input, condition: $condition) {
      id
      userId
      leadId
      type
      eventDate
      callbackDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;

export const updateLead = /* GraphQL */ `
  mutation UpdateLead(
    $input: UpdateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    updateLead(input: $input, condition: $condition) {
      id
      clientId
      openerId
      closerId
      providerId
      provider {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      pos
      score
      createdAt
      updatedAt
      closed
      deleted
      notes {
        items {
          id
          owner
          note
          createdAt
          updatedAt
          leadId
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      tags
      status
      statusChangeDate
      transferType
      transferDate
      isVerified
      eventId
      leadsCount
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createEventCalendar = /* GraphQL */ `
  mutation CreateEventCalendar(
    $input: CreateEventCalendarInput!
    $condition: ModelEventCalendarConditionInput
  ) {
    createEventCalendar(input: $input, condition: $condition) {
      id
      userId
      leadId
      type
      eventDate
      callbackDate
      createdAt
      updatedAt
      owner
    }
  }
`;

export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      owner
      note
      createdAt
      leadId
    }
  }
`;

export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      name
      isCompany
      referentName
      phone
      email
      language
      address
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      name
      isCompany
      referentName
      phone
      email
      language
      address
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      role
      status
      }  
  }
`;

export const deleteEventCalendar = /* GraphQL */ `
  mutation DeleteEventCalendar(
    $input: DeleteEventCalendarInput!
    $condition: ModelEventCalendarConditionInput
  ) {
    deleteEventCalendar(input: $input, condition: $condition) {
      id
      userId
      leadId
      type
      eventDate
      callbackDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
