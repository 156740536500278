import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  Layout,
  Modal,
  Spin,
  Radio,
} from "antd";
import {
  mutationCreateNote,
  getProviders,
  saveLead,
  deleteLead,
  addCallback,
  updateTransferType,
  getClosers,
  delay,
} from "./utils/dataHooks";
import { Descriptions, message } from "antd";
import { Content } from "antd/es/layout/layout";
import { Auth } from "aws-amplify";
import dayjs from "dayjs";

const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 4,
    span: 20,
  },
};
const inputwidth = 350;

const FormLead_opener = (props) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCloserModalOpen, setIsCloserModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [disableButtons, setDisableButtons] = useState(false);
  const [providers, setProviders] = useState(null);
  const [closers, setClosers] = useState(null);
  const [user, setUser] = useState(null);
  // it is for preventing unsaving data
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProviders = async () => {
      const providers = await getProviders();
      const closers = await getClosers();
      setUser(await Auth.currentAuthenticatedUser());
      var providersList = [];
      var closersList = [];
      var other;
      var noProcessor;
      providers.forEach((provider) => {
        if (provider.name === "Other") {
          other = {
            label: provider.name,
            value: provider.id,
          };
        } else if (provider.name === "No processor") {
          noProcessor = {
            label: provider.name,
            value: provider.id,
          };
        } else {
          providersList.push({
            label: provider.name,
            value: provider.id,
          });
        }
      });
      closers.forEach((closer) => {
        closersList.push({
          label: closer.name,
          value: closer.id,
        });
      });
      providersList.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
      providersList.push(other);
      providersList.unshift(noProcessor);
      setProviders(providersList);
      setClosers(closersList);
    };
    fetchProviders();
  }, []);

  useEffect(() => {
    if (props?.leadInfo) {
      form.setFieldsValue({
        entreprise: props.leadInfo ? props.leadInfo?.lead?.client?.name : null,
        nom: props.leadInfo ? props.leadInfo?.lead?.client?.referentName : null,
        tel: props.leadInfo ? props?.leadInfo?.lead?.client?.id : null,
        tel2: props.leadInfo ? props?.leadInfo?.lead?.client?.phone : null,
        email: props.leadInfo ? props.leadInfo?.lead?.client?.email : null,
        score: props.leadInfo ? props.leadInfo?.lead?.score : null,
        fournisseur: props.leadInfo?.lead?.provider
          ? props?.leadInfo?.lead?.provider?.id
          : null,
        language: props.leadInfo ? props.leadInfo?.lead?.client?.language : null,
        pos: props.leadInfo ? props.leadInfo?.lead?.pos : null,
      });
    }
  }, [props]);


    const readonlyitems = [
      {
        key: "1",
        label: "Lead Creator",
        children: props?.leadInfo?.lead?.opener?.name,
      },
      {
        key: "2",
        label: "Next Callback",
        children:
          (props?.leadInfo?.callbackDate &&
          dayjs(props?.leadInfo?.callbackDate).format("YYYY-MM-DD HH:mm")) || 
          (props?.leadInfo?.lead?.event?.callbackDate &&
          dayjs(props?.leadInfo?.lead?.event?.callbackDate).format("YYYY-MM-DD HH:mm")),
      },
    ]


  const showCallBackModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showCloserModal = () => {
    setIsCloserModalOpen(true);
  };

  const handleLiveCancel = () => {
    if (!props.leadInfo) {
      form.setFieldValue("closer", null);
    }
    setIsCloserModalOpen(false);
  };

  const onSave = () => {
    setIsLoading(true);
    messageApi.info(
      "Saving... If you go somewhere else, changes might not be saved."
    );

    saveLead(
      props.leadInfo,
      form.getFieldValue(),
      props.setContainedData,
      props.setLoaded
    );
  };

  const onCallBack = () => {
    setIsLoading(true);
    messageApi.info(
      "Saving... If you go somewhere else, changes might not be saved."
    );
    const nextDate = form.getFieldValue().callbackDate;
    addCallback(
      props.leadInfo,
      form.getFieldValue().callbackDate,
      props.setLoaded
    );

    if(props?.setIsDrawerOpen){
      props.setIsDrawerOpen(false);
    }
  };

  const onWarmTransfer = () => {
    updateTransferType(props.leadInfo, "Warm", null, props.setLoaded);
    messageApi.info("Set to Warm transfer");
    if(props?.setIsDrawerOpen){
      props.setIsDrawerOpen(false);
    }
    if(props?.setLoaded){
      props.setLoaded(false);
    }
    if(props?.setIsDrawerOpen){
      props.setIsDrawerOpen(false);
    }


  };

  const onLiveTransfer = () => {
    updateTransferType(
      props.leadInfo,
      "Live",
      form.getFieldValue("closer"),
      props.setLoaded
    );
    messageApi.info(
      "Transferred to " +
        closers.filter(
          (closer) => closer.value === form.getFieldValue("closer")
        )[0].label
    );
    setIsCloserModalOpen(false);

    if(props?.setIsDrawerOpen){
      props.setIsDrawerOpen(false);
    }
    if (props?.parentData){
      const filtered_data = props.parentData.filter((item)=>{return item.id !== props.leadInfo.id})
      props.setParentData(filtered_data)
      props.setParentContainedData({})
    }else{
      if(props?.setLoaded){
        props.setLoaded(false);
      }
    }
  };

  /* This is for the Calendar selection
   */
  const onChange = (value, dateString) => {
    form.setFieldValue("callbackDate", value);
  };
  const onOk = (value) => {
    // console.log("onOk: ", value);
    form.setFieldValue("callbackDate", value);
  };

  const onDelete = () => {
    if (props.leadInfo) {
      deleteLead(props?.leadInfo, props?.setLoaded);
    }
    form.resetFields();
    messageApi.info("Dropped Lead");
    if(props?.setIsDrawerOpen){
      props.setIsDrawerOpen(false);
    }
    if (props?.parentData){
      const filtered_data = props.parentData.filter((item)=>{return item.id !== props.leadInfo.id})
      props.setParentData(filtered_data)
      props.setParentContainedData({})
    }else{
      if(props?.setLoaded){
        props.setLoaded(false);
      }
    }
    
  };

  //Disable buttons if nom, entreprise and tel are not filled
  const onValuesChange = (changedValues, values) => {
    if (
      values.nom != undefined &&
      values.nom !== "" &&
      values.entreprise != undefined &&
      values.entreprise !== "" &&
      values.tel != undefined &&
      values.tel !== ""
    ) {
      setDisableButtons(false);
    } else {
      setDisableButtons(true);
    }
  };
  if (!providers) return <Spin size="large" />;
  else {
    return (
      <Form
        {...layout}
        form={form}
        name="control-hooks"
        // onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Layout style={{ background: "white" }}>
          {contextHolder}
          <Content>
            <Form.Item>
      {readonlyitems.map((item) => (
        <Descriptions key={item.key} items={[item]} />
      ))}
    </Form.Item>

            <Form.Item
              name="entreprise"
              label="Company name"
              initialValue={
                props.leadInfo ? props?.leadInfo?.lead?.client?.name : null
              }
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
            <Form.Item
              name="nom"
              label="Owner Name"
              initialValue={
                props.leadInfo
                  ? props.leadInfo?.lead?.client?.referentName
                  : null
              }
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
            <Form.Item
              name="tel"
              label="Phone Number"
              initialValue={props?.leadInfo?.lead?.client?.id}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ width: inputwidth }}
                // onChange={checkPhone}
                disabled={true}
              />
            </Form.Item>
            <Form.Item
        name="score"
        label="Score"
      >
    <Radio.Group>
          <Space direction="horizontal" size="large">
            <Radio.Button value="Hot" style={{ display: 'flex', alignItems: 'center', padding: '0 12px' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: 'green', marginRight: 8 }} />
                Hot
              </span>
            </Radio.Button>
            <Radio.Button value="Warm" style={{ display: 'flex', alignItems: 'center', padding: '0 12px' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: 'orange', marginRight: 8 }} />
                Warm
              </span>
            </Radio.Button>
            <Radio.Button value="Cold" style={{ display: 'flex', alignItems: 'center', padding: '0 12px' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: 'red', marginRight: 8 }} />
                Cold
              </span>
            </Radio.Button>
          </Space>
        </Radio.Group>
      </Form.Item>
            <Form.Item
              name="tel2"
              label="Phone Number 2"
              initialValue={props?.leadInfo?.lead?.client?.phone}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              initialValue={props.leadInfo?.lead?.client?.email}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
            <Form.Item
              name="fournisseur"
              label="Processor"
              initialValue={
                props.leadInfo?.lead?.provider
                  ? props?.leadInfo?.lead?.provider?.id
                  : null
              }
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                placeholder="Select a option and change input text above"
                style={{ width: inputwidth }}
                allowClear
                options={providers}
              ></Select>
            </Form.Item>
            <Form.Item
              name="language"
              label="Language"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                placeholder="Select an option and change input text above"
                style={{ width: inputwidth }}
                allowClear
                options={["French", "English", "Other"].map((item)=>{return {label:item, value:item}})}
              ></Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.gender !== currentValues.gender
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("gender") === "other" ? (
                  <Form.Item
                    name="customizeGender"
                    label="Customize Gender"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input style={{ width: inputwidth }} />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
            <Form.Item
              name="pos"
              label="POS"
              initialValue={props.leadInfo ? props.leadInfo?.lead?.pos : null}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input style={{ width: inputwidth }} />
            </Form.Item>
          </Content>
          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={onSave}
              disabled={disableButtons || isLoading}
            >
              Save
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              style={{ margin: "0 8px" }}
              onClick={showCallBackModal}
              disabled={disableButtons || isLoading}
            >
              Set Callback
            </Button>

            <Modal
              title="Set Callback"
              open={isModalOpen}
              onOk={onCallBack}
              onCancel={handleCancel}
            >
              <Form.Item name="callbackDate" label="Callback Date">
                <Space direction="vertical" size={12}>
                  <DatePicker
                    showTime
                    onChange={onChange}
                    onOk={onOk}
                    minuteStep={5}
                    format={"DD/MM/YY hh:mm A"}
                  />
                </Space>
              </Form.Item>
            </Modal>

            <Button
              type="primary"
              htmlType="submit"
              style={{ margin: "0 8px" }}
              onClick={onWarmTransfer}
              disabled={disableButtons || isLoading}
            >
              Warm Transfer
            </Button>

            <p></p>

            <Button
              type="primary"
              htmlType="submit"
              onClick={showCloserModal}
              disabled={disableButtons || isLoading}
            >
              Live Transfer
            </Button>

            <Modal
              title="Assign to a closer"
              open={isCloserModalOpen}
              onOk={onLiveTransfer}
              onCancel={handleLiveCancel}
            >
              <Form.Item
                name="closer"
                initialValue={
                  props.leadInfo?.lead?.closer?.name
                    ? props?.leadInfo?.lead?.closer?.id
                    : null
                }
              >
                <Select
                  placeholder="Select a option and change input text above"
                  style={{ width: inputwidth }}
                  allowClear
                  options={closers}
                ></Select>
              </Form.Item>
            </Modal>

            <Button
              type="primary"
              htmlType="submit"
              style={{ margin: "0 8px", background: "red" }}
              onClick={onDelete}
              disabled={disableButtons || isLoading}
            >
              Drop Lead
            </Button>
          </Form.Item>
        </Layout>
      </Form>
    );
  }
};
export default FormLead_opener;
